import React, {useEffect, useState} from 'react';
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import axios from "axios";
import IssueAdd from "./issueAdd";
import IssueEdit from "./issueEdit";
import YourIssueRow from "./yourIssueRow";
import {useDispatch, useSelector} from "react-redux";
import {isEmptyObject} from "../utils";
import {actionFetchProfile} from "../state/actionsProfile";
import YourIssueView from "./yourIssueView";
import {useHistory, useParams} from "react-router-dom";

export default function YourIssueList(props) {
	const dispatch = useDispatch();
	const {id} = useParams();
	const history = useHistory();

	const me = useSelector(state => state.profile.me);
	const meLoading = useSelector(state => state.profile.loading);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [count, setCount] = useState(1);

	const [search, setSearch] = useState("");
	const [issues, setIssues] = useState(null);
	const [issueId, setIssueId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('created');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalView, setShowModalView] = useState(false);

	useEffect(() => {
		if (id) {
			handleOpenModalView(id)
		} else {
			setShowModalView(false);
		}
	}, [id]);

	useEffect(() => {
		if (meLoading === false && isEmptyObject(me)) {
			dispatch(actionFetchProfile());
		}
	}, []);

	async function fetchIssues() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		if (me.id) {
			const response = await axios.get(API_URL + '/issue/assigned/' + me.id, {params: toSend});

			let data = response.data.data;
			//console.log(data);
			if (Array.isArray(data.list)) {
				setIssues(data.list);
				setCount(data.count);
			}
		}
	}

	useEffect(() => {
		fetchIssues();
	}, [search, currentPage, perPage, orderDirection, orderColumn, me]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalView() {
		history.push("/yourIssues");
	}

	function handleOpenModalView(id) {
		setIssueId(id);
		setShowModalView(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setIssueId(id);
		setShowModalEdit(true);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleRefresh() {
		fetchIssues();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Twoje zgłoszenia</h1>
					<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</button>
				</div>
				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="timeRequired" value={(orderColumn === 'timeRequired') ? orderDirection : null}>Wymagany czas</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealization" value={(orderColumn === 'dateRealization') ? orderDirection : null}>Graniczna data zakończenia</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="orderedBy" value={(orderColumn === 'orderedBy') ? orderDirection : null}>Zlecający</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="user" value={(orderColumn === 'user') ? orderDirection : null}>Przydzielono do</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="created" value={(orderColumn === 'created') ? orderDirection : null}>Utworzono</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="status" value={(orderColumn === 'status') ? orderDirection : null}>Status</Sortable></div>
					<div className="boxCell"/>
				</div>

				{issues && issues.map(function(item, index) {
					return [
						<YourIssueRow
							key={'yourIssue' + index}
							value={item}
							handleOpenModalEdit={handleOpenModalEdit}
							handleOpenModalView={handleOpenModalView}
							refresh={handleRefresh}
						/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(issues === null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={count}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={count}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filterKnowledgeType}/>*/}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<IssueAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<IssueEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} issueId={issueId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<YourIssueView handleRefresh={handleRefresh} handleClose={handleCloseModalView} issueId={issueId} />
			</ReactModal>
		</div>
	);
}
