import React from 'react'
import axios from 'axios'
import {Form, Formik} from "formik"
import {useHistory} from "react-router-dom"
import BackButton from "../component/backButton";
import ManagePeople from "../form/managePeople";
import {objectClone} from "../utils";
import ClientForm, {ClientFormNewContact, validateClient} from "./clientForm";
import {formikErrorHelper} from "../form/formUtils";

export default function ClientAdd(props) {
	const history = useHistory();
	let managePeople = React.createRef();

	function saveClientPersons(toSave, id) {
		let savePromisesArray = [];

		toSave.map((item) => {
			item.client = id;
			savePromisesArray.push(
				axios.post(API_URL + '/clientPerson/create', item)
			);
		});

		Promise.all(savePromisesArray).then(() => history.goBack());
	}

	function handleSubmit(values, bag) {
		let toSave = objectClone(values);

		if (toSave.hasOwnProperty('province')) {
			toSave['province'] = parseInt(toSave['province']);
		}
		if (toSave.hasOwnProperty('fvProvince')) {
			toSave['fvProvince'] = parseInt(toSave['fvProvince']);
		}

		let newPeople = toSave.newPeople;
		delete toSave.newPeople;

		axios.post(API_URL + '/client/create', toSave)
			.then(function(response) {
				bag.setSubmitting(false);
				let clientId = response.data.id;

				if (clientId) {
					managePeople.current.save(clientId, () => {
						saveClientPersons(newPeople, clientId);
					});
				}
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<Formik
			initialValues={{
				name: "",
				phone: "",
				email: "",
				city: "",
				street: "",
				postalCode: "",
				province: "",

				fvName: "",
				fvPhone: "",
				fvEmail: "",
				nip: "",
				fvCity: "",
				fvStreet: "",
				fvPostalCode: "",
				fvProvince: "",

				newPeople: [],
				color: '#FF0000',
			}}
			validate={validateClient}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

				return (
					<Form>
						<div className="headerWithButtons">
							<h1>Dodaj klienta</h1>
							<div className="right">
								<BackButton>Anuluj</BackButton>
								<button type="submit" disabled={isSubmitting} className="left32 withChevron">
									Dodaj&nbsp;<i className="fas fa-chevron-right"/>
								</button>
							</div>
						</div>

						<div className="box">
							<ClientForm {...propsFormik}/>
						</div>

						<ClientFormNewContact {...propsFormik}/>

						{status && <div className="errorMessage">{status}</div>}

						<ManagePeople ref={managePeople}/>

						{/*<Dump value={values} />*/}
					</Form>
				)
			}}
		</Formik>
	);
}
