import React, {useContext, useState} from "react";
import {NavLink} from "react-router-dom";
import Dump from "./component/dump";
import {AccessContext} from "./access";

export default function Menu(props) {
	const access = useContext(AccessContext);
	const [collapsed, setCollapsed] = useState(true);

	function renderDefinitions() {
		if (access.admin === false && access.manager === false) {
			return '';
		}

		return (
			<li>
				<span onClick={(e) => setCollapsed(!collapsed)} style={{marginLeft: -12, cursor: 'pointer'}}>
					<i className={"fas " + (collapsed ? "fa-caret-right" : "fa-caret-down")} style={{width: 12}}/>
					&nbsp;Definicje
				</span>
				<ul style={{display: collapsed ? 'none' : 'block'}}>
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/company">Spółki</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/department">Działy</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/projectType">Typy usług</NavLink></li> : ''}
					<li><NavLink activeClassName="selected" to="/admin/service">Usługi</NavLink></li>
					<li><NavLink activeClassName="selected" to="/admin/procedure">Procedury</NavLink></li>
					<li><NavLink activeClassName="selected" to="/admin/task">Zadania</NavLink></li>
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/taskType">Typy zadań</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/calendarType">Typy kalendarza</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/documentType">Typy dokumentów</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/position">Stanowiska</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/receptionType">Typy przesyłek</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/knowledgeBaseType">Typy bazy wiedzy</NavLink></li> : ''}
					{access.admin ? <li><NavLink activeClassName="selected" to="/admin/tag">Tagi</NavLink></li> : ''}
				</ul>
			</li>
		);
	}

	return (
		<nav>
			<ul>
				<li><NavLink activeClassName="selected" to="/task">Twoje zadania</NavLink></li>
				<li><NavLink activeClassName="selected" to="/yourIssues">Twoje zgłoszenia</NavLink></li>
				{access.manager ? <li><NavLink activeClassName="selected" to="/project">Projekty</NavLink></li> : ''}
				{access.manager ? <li><NavLink activeClassName="selected" to="/issue">Zgłoszenia</NavLink></li> : ''}
				<li><NavLink activeClassName="selected" to="/client">Klienci</NavLink></li>
				<li><NavLink activeClassName="selected" to="/team">Zespół</NavLink></li>
				<li><NavLink activeClassName="selected" to="/document">Dokumenty</NavLink></li>
				<li><NavLink activeClassName="selected" to="/knowledge">Baza wiedzy</NavLink></li>
				{access.reception ? <li>
					<span>Recepcja</span>
					<ul>
						<li><NavLink activeClassName="selected" to="/guest">Goście</NavLink></li>
						<li><NavLink activeClassName="selected" to="/package">Przesyłki</NavLink></li>
					</ul>
				</li> : ''}
				{renderDefinitions()}
			</ul>

			<Dump value={access}/>
		</nav>
	);
}
