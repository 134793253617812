import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom"
import ClientRow from "./clientRow";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import FilterTags from "../component/filterTag";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import {actionFetchPeople} from "../state/actionsPeople";
import {connect, useDispatch} from "react-redux";
import ReactModal from "react-modal";
import FilterClient from "./filterClient";
import {getNameFromId, objectClone} from "../utils";
import Dump from "../component/dump";

const mapStateToProps = function(state) {
	return {
		people: state.people.list,
		peopleLoading: state.people.loading,
	};
};

function ClientList(props) {
	const dispatch = useDispatch();

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [clientsCount, setClientsCount] = useState(1);

	const [filters, setFilters] = useState({});
	const [filterSelectedTag, setFilterSelectedTag] = useState(null);
	const [search, setSearch] = useState("");

	const [clients, setClients] = useState(null);

	const [orderColumn, setOrderColumn] = useState(0);
	const [orderDirection, setOrderDirection] = useState(null);

	const [showModalFilters, setShowModalFilters] = useState(false);

	useEffect(() => {
		if (props.peopleLoading === false && (props.people == null || props.people.length === 0)) {
			dispatch(actionFetchPeople());
		}
	}, []);

	async function fetchClients() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.name = search;
		}

		if (filterSelectedTag) {
			toSend["filter[tags]"] = filterSelectedTag;
		}

		if (filters && filters.hasOwnProperty('persons')) {
			toSend['filter[persons]'] = filters.persons;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/client/list', {params: toSend});

		let data = response.data.data;
		//console.log(data);
		if (Array.isArray(data.list)) {
			setClients(data.list);
			setClientsCount(data.count);
		}
	}

	useEffect(() => {
		fetchClients();
	}, [search, filters, filterSelectedTag, currentPage, perPage, orderDirection, orderColumn]);

	function handleSortChange(index, direction) {
		setOrderDirection(direction);
		setOrderColumn(index);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	function handleRefresh() {
		fetchClients();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Klienci</h1>
					<Link to="/client/add" className="button left32 withChevron">
						Dodaj klienta&nbsp;<i className="fas fa-plus"/>
					</Link>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>

				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={function(event) { setSearch(event.target.value); }}/>
				</div>
			</div>

			<FilterTags onChange={value => setFilterSelectedTag(value)} />

			<div className="filters">
				{filters.persons && filters.persons.map((item) => {
					return (
						<span key={"filterPersons" + item} className="filter">
							<i className="fas fa-times" onClick={() => {
								let newFilters = objectClone(filters);
								if (newFilters.persons.indexOf(item) > -1) {
									newFilters.persons.splice(newFilters.persons.indexOf(item), 1);
									setFilters(newFilters);
								}
							}}/>
							{getNameFromId(props.people, item)}
						</span>
					);
				})}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Klient</Sortable></div>
					<div className="boxCell">Opiekun klienta</div>
					<div className="boxCell">Osoba kontaktowa</div>
					<div className="boxCell"/>
					<div className="boxCell"/>
				</div>

				{clients && clients.map(function(item, index) {
					return [
						<ClientRow key={'client' + index} value={item} refresh={handleRefresh}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(clients == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={clientsCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			<Dump value={filters}/>
			{/*<Dump value={filterSelectedTag}/>*/}
			{/*{<Dump label="column" value={orderColumn}/>}*/}
			{/*{<Dump label="direction" value={orderDirection}/>}*/}

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<FilterClient filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>
		</div>
	);
}

export default connect(mapStateToProps)(ClientList);
