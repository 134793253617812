import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {formatLocalizedDateTime, isEmptyObject} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";

export default function GuestView(props) {

	const [guest, setGuest] = useState({});

	useEffect(() => {
		async function fetchGuest() {
			const response = await axios(API_URL + '/reception/' + props.packageId);
			//console.log(response);

			if (response.data.data.reception) {
				setGuest(response.data.data.reception);
			}
		}

		fetchGuest();
	}, [props.packageId]);

	return (isEmptyObject(guest) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{guest.fullName}</h3>

					<div className="row with two columns">

						<div className="formItem column">
							<label htmlFor="clientName">Nazwa firmy</label>
							<input type="text" readOnly value={guest.client ? guest.client.name : guest.clientName} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="implementationDate">Data i godzina wejścia</label>
							<input type="text" readOnly value={formatLocalizedDateTime(parseISO(guest.operationDate))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="province">Do kogo</label>
							<input type="text" readOnly value={guest.user ? guest.user.name : ''} className="fullWidth"/>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Notatka</label>
						<textarea className="fullWidth" value={guest.description} name="description" rows="4"/>
					</div>

					<div className="row with four columns">
						{guest.files && guest.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					<div className="rcModalActions">
						<button type="submit" className="left32">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
