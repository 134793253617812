import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {formatLocalizedDate, isEmptyObject} from "../../utils";
import Spinner from "../../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../../component/fileView";

export default function AdminProcedureView(props) {

	const [procedure, setProcedure] = useState({});

	useEffect(() => {
		async function fetchTask() {
			const response = await axios(API_URL + '/definition/procedure/' + props.procedureId);
			//console.log(response.data.data);
			let procedure = response.data.data.procedure;
			if (procedure) {
				if (procedure.tasks) {
					let sortedTasks = [...procedure.tasks];

					sortedTasks.sort(function(a, b) {
						if (a.position > b.position) {
							return 1;
						}
						if (a.position < b.position) {
							return -1;
						}
						return 0;
					});

					procedure.tasks = sortedTasks;
				}

				setProcedure(procedure);
			}
		}

		fetchTask();
	}, [props.procedureId]);

	return (isEmptyObject(procedure) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderTasks(tasks) {
		return <>
			<div className="formItem bottom0">
				<label>Zadania w procedurze</label>
			</div>

			{tasks.map((item) => {
				return (<div className="boxItem small bottom12" key={"task" + item.id}>{item.name}</div>);
			})}
		</>
	}

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{procedure.name}</h3>

					<div className="row with four columns">
						<div className="formItem column">
							<label htmlFor="name">Przypisanie do usługi</label>
							<input type="text" readOnly value={procedure.service} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="name">Przypisanie do działu</label>
							{(procedure.department) ? <span className="pill eggplant">{procedure.department.name}</span> : ''}
						</div>

						<div className="formItem column">
							<label>Data ostatniej zmiany</label>
							<input type="text" readOnly value={formatLocalizedDate(parseISO(procedure.modified))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Przez kogo</label>
							<input type="text" readOnly value={procedure.userModified ? procedure.userModified.name : ''} className="fullWidth"/>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Opis procedury</label>
						<textarea className="fullWidth" name="description" value={procedure.description} rows="4" readOnly/>
					</div>

					<div className="row with four columns">
						{procedure.files && procedure.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					{(procedure.tasks && Array.isArray(procedure.tasks) && procedure.tasks.length > 0) ? renderTasks(procedure.tasks) : ''}

					<div className="rcModalActions">
						<button type="submit">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
