import React, {useEffect, useState} from 'react';
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import axios from "axios";
import NotificationRow from "./notificationRow";
import {formatISO, parse, parseISO} from 'date-fns';
import {formatLocalizedDate} from "../utils";
import {actionFetchNotificationCount} from "../state/actionsNotifications";
import {useDispatch} from "react-redux";
import usePushNotifications from "../usePushNotifications";

export default function NotificationList(props) {
	const dispatch = useDispatch();

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [count, setCount] = useState(1);

	const [search, setSearch] = useState("");
	const [notifications, setNotifications] = useState(null);

	async function fetchNotifications() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		const response = await axios.get(API_URL + '/notification/list', {params: toSend});

		let data = response.data.data.list;
		let parsedData = {};

		if (Array.isArray(data.list)) {
			data.list.map(function(item) {
				if (item.created) {
					let prop = formatISO(parseISO(item.created), {representation: 'date'})

					if (parsedData.hasOwnProperty(prop) === false) {
						parsedData[prop] = [item]
					} else {
						parsedData[prop].push(item);
					}
				}
			});

			setNotifications(parsedData);
			setCount(data.count);
		}
	}

	useEffect(() => {
		fetchNotifications();
	}, [search, currentPage, perPage]);

	useEffect(() => {
		dispatch(actionFetchNotificationCount());
	}, []);

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleRefresh() {
		dispatch(actionFetchNotificationCount());
		fetchNotifications();
	}

	const {
		userConsent,
		isPushNotificationSupported,
		askUserPermission,
	} = usePushNotifications();

	const isConsentGranted = userConsent === "granted";

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Powiadomienia</h1>
				</div>
				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			{(isPushNotificationSupported && !isConsentGranted) ? <button onClick={askUserPermission}>Zgoda na powiadomienia</button> : ''}

			{notifications && Object.keys(notifications).map(function(date, index) {
				return [
					<h6 className="textBold top20" key={'notificationHeader' + index}>
						{formatLocalizedDate(parse(date, 'yyyy-MM-dd', new Date()))}
					</h6>,
					<div className="boxTable" key={'notificationTable' + index}>
						{
							notifications[date].map(function(notification, notificationIndex) {
								return [
									<NotificationRow
										key={'notification' + index + notificationIndex}
										value={notification}
										refresh={handleRefresh}
									/>,
									<div key={'spacer' + index + notificationIndex} className="boxRow spacer"/>
								];
							})
						}
					</div>
				];
			})}

			{(notifications === null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={count}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={notifications}/>*/}
		</div>
	);
}
