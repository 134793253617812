import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
	findIndexOfObjectWithPropertyValueInArray,
	formatLocalizedDate,
	formatLocalizedDateTime,
	isEmptyObject,
	objectClone
} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";
import CommentView from "../component/commentView";
import CommentAdd from "../component/commentAdd";
import FileUpload from "../component/fileUpload";
import PillStatus from "../component/pillStatus";
import {arrayPeopleToNameString} from "./utilIssue";

export default function IssueView(props) {

	const [issue, setIssue] = useState({});

	async function fetchIssue() {
		const response = await axios(API_URL + '/issue/' + props.issueId);
		//console.log(response.data.data);
		if (response.data.data.user) {
			let foo = objectClone(response.data.data.user);

			if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('name')) {
				foo.client = foo.client.name;
			}

			if (foo.hasOwnProperty('orderedBy') && foo.orderedBy.hasOwnProperty('name')) {
				foo.orderedBy = foo.orderedBy.name;
			}

			if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('name')) {
				foo.user = foo.user.name;
			}

			setIssue(foo);
		}
	}

	useEffect(() => {
		fetchIssue();
	}, [props.issueId]);

	function handleFileAdd(uploadedFile) {
		axios.post(API_URL + '/issue/file/' + props.issueId + '/' + uploadedFile.id)
			.then(function(response) {
				fetchIssue();
			});
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(issue.files, fileId);
		if (index !== null && index !== -1) {
			axios.delete(API_URL + '/issue/file/' + props.issueId + '/' + fileId)
				.then(function(response) {
					fetchIssue();
				});
		}
	}

	function handleCommentAdded(newCommentId) {
		axios.post(API_URL + '/issue/comment/' + props.issueId + '/' + newCommentId)
			.then(function(response) {
				fetchIssue();
			});
	}

	return (isEmptyObject(issue) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{issue.name}</h3>

					<div className="row with four columns">
						<div className="formItem column">
							<label htmlFor="name">Osoba zlecająca</label>
							<input type="text" readOnly value={issue.orderedBy} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Klient</label>
							<input type="text" readOnly value={issue.client} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Graniczna data zakończenia</label>
							<input type="text" readOnly value={formatLocalizedDate(parseISO(issue.dateRealization))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Status</label>
							<PillStatus value={issue.status}/>
						</div>
					</div>

					<div className="formItem">
						<label>Przydzielono do</label>
						<input type="text" readOnly value={arrayPeopleToNameString(issue.users)} className="fullWidth"/>
					</div>

					<div className="row with four columns">
						<div className="formItem column">
							<label>Data przydzielenia</label>
							<input type="text" readOnly value={formatLocalizedDateTime(parseISO(issue.assignDate))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Prognozowany termin wykonania</label>
							<input type="text" readOnly value={formatLocalizedDate(parseISO(issue.dateRealizationEstimation))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Wymagany czas</label>
							<input type="text" readOnly value={issue.timeRequired} className="noMinimumWidth" style={{width: 100}}/>
							&nbsp;&nbsp;godz.
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Opis</label>
						<textarea className="fullWidth" name="description" value={issue.description} rows="4" readOnly/>
					</div>

					<h4>Komentarze</h4>
					{issue.comments && issue.comments.map(function(item) {
						return <CommentView
							commentId={item.id}
							text={item.text}
							date={item.created}
							poster={item.createdBy ? item.createdBy.name : ''}
							posterId={item.createdBy ? item.createdBy.id : ''}
							refresh={fetchIssue}
							key={"comment" + item.id}
						/>;
					})}
					<CommentAdd handleAdd={handleCommentAdded}/>

					<div className="row with four columns">
						{issue.files && issue.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id)}/>
								</div>
							);
						})}
					</div>

					<FileUpload handleFile={handleFileAdd}/>

					<div className="rcModalActions">
						<button type="submit" className="left32">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
