import React from "react";

import {Route, Switch} from "react-router";
import {ConnectedRouter as Router} from 'connected-react-router';
import {history} from '../configureStore';
import {AccessContext, access, hasManagerRole, hasAdminRole, hasReceptionRole} from "./access";
import {useSelector} from "react-redux";

import Layout from "./layout";
import NotFound404 from "./notFound404";

import FormTest from "./form/formTest";

import Login from "./auth/login";
import PasswordRecovery from "./auth/passwordRecovery";
import PasswordRecoveryChange from "./auth/passwordRecoveryChange";
import PasswordRecoveryMessage from "./auth/passwordRecoveryMessage";

import TeamMembers from "./team/teamMembers";
import TeamStructure from "./team/teamStructure";

import ProfileAdd from "./profile/profileAdd";
import ProfileView from "./profile/profileView";
import ProfileEdit from "./profile/profileEdit";

import CalendarEventPage from "./calendar/calendarEventPage";
import CalendarAbsencePage from "./calendar/calendarAbsencePage";

import ClientList from "./client/clientList";
import ClientAdd from "./client/clientAdd";
import ClientView from "./client/clientView";
import ClientEdit from "./client/clientEdit";

import AdminTaskList from "./admin/task/adminTaskList";
import AdminProcedureList from "./admin/procedure/adminProcedureList";
import AdminServiceList from "./admin/service/adminServiceList";

import AdminTaskTypeList from "./admin/taskType/adminTaskTypeList";
import AdminCompanyList from "./admin/company/adminCompanyList";
import AdminDepartmentList from "./admin/department/adminDepartmentList";
import AdminProjectTypeList from "./admin/projectType/adminProjectTypeList";
import AdminPositionList from "./admin/position/adminPositionList";
import AdminCalendarTypeList from "./admin/calendarType/adminCalendarTypeList";
import AdminDocumentTypeList from "./admin/documentType/adminDocumentTypeList";
import AdminReceptionTypeList from "./admin/receptionType/adminReceptionTypeList";
import AdminTagList from "./admin/tag/adminTagList";
import AdminKnowledgeBaseTypeList from "./admin/knowledgeBaseType/adminKnowledgeBaseTypeList";

import KnowledgeList from "./knowledge/knowledgeList";
import DocumentList from "./document/documentList";

import TaskList from "./task/taskList";
import IssueList from "./issue/issueList";
import YourIssueList from "./issue/yourIssueList";

import ProjectList from "./project/projectList";
import ProjectAdd from "./project/projectAdd";
import ProjectEdit from "./project/projectEdit";

import GuestList from "./reception/guestList";
import GuestAdd from "./reception/guestAdd";
import GuestEdit from "./reception/guestEdit";

import PackageList from "./reception/packageList";
import PackageAdd from "./reception/packageAdd";
import PackageEdit from "./reception/packageEdit";

import NotificationList from "./notification/notificationList";
import PushNotification from "./notification/pushNotification";

export default function App(props) {
	const me = useSelector(state => state.profile.me);

	if (me) {
		access.id = me.id;

	    if (me.hasOwnProperty('roles')) {
			access.admin = hasAdminRole(me.roles);
			access.manager = hasManagerRole(me.roles);
			access.reception = hasReceptionRole(me.roles);
		}
	}

	return (
		<AccessContext.Provider value={access}>
			<Router history={history}>
				<Switch>
					<Route exact path="/" render={routeProps => <Layout><TaskList/></Layout>} />
					<Route exact path="/task" render={routeProps => <Layout><TaskList/></Layout>} />
					<Route exact path="/task/:projectId/:taskId/:procedureId/:serviceId" render={routeProps => <Layout><TaskList/></Layout>} />

					<Route exact path="/issue" render={routeProps => <Layout><IssueList/></Layout>} />
					<Route exact path="/yourIssues" render={routeProps => <Layout><YourIssueList/></Layout>} />
					<Route exact path="/yourIssues/:id" render={routeProps => <Layout><YourIssueList/></Layout>} />

					{(DEBUG === 'true') ? (<Route exact path="/form" render={routeProps => <Layout><FormTest/></Layout>} />) : null}

					<Route path="/team" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/structure`} component={TeamStructure} />
								<Route exact path={`${routeProps.match.path}/calendar`} component={CalendarAbsencePage} />
								<Route exact path={`${routeProps.match.path}/add`} component={ProfileAdd} />
								<Route exact path={`${routeProps.match.path}/edit/:id`} render={routeProps => <ProfileEdit title="Edytuj profil pracownika" subTitle={"Dane pracownika"}/>} />
								<Route exact path={`${routeProps.match.path}/view/:id`} render={routeProps => <ProfileView title="Profil pracownika" subTitle={"Dane pracownika"} link="/team"/>} />
								<Route component={TeamMembers} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="/profile" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/edit/:id`} render={routeProps => <ProfileEdit title="Edytuj twój profil" subTitle={"Twoje dane"}/>} />
								<Route exact path={`${routeProps.match.path}/view/:id`} render={routeProps => <ProfileView title="Twój profil" subTitle={"Twoje dane"} link="/profile"/>} />
								<Route component={TeamMembers} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="/project" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/add`} component={ProjectAdd} />
								<Route exact path={`${routeProps.match.path}/edit/:id`} component={ProjectEdit} />
								<Route component={ProjectList} />
							</Switch>
						</Layout>}>
					</Route>

					<Route exact path="/document" render={routeProps => <Layout title="Dokumenty"><DocumentList/></Layout>} />
					<Route exact path="/knowledge" render={routeProps => <Layout><KnowledgeList/></Layout>} />

					<Route path="/admin" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/task`} component={AdminTaskList} />
								<Route exact path={`${routeProps.match.path}/procedure`} component={AdminProcedureList} />
								<Route exact path={`${routeProps.match.path}/taskType`} component={AdminTaskTypeList} />
								<Route exact path={`${routeProps.match.path}/company`} component={AdminCompanyList} />
								<Route exact path={`${routeProps.match.path}/department`} component={AdminDepartmentList} />
								<Route exact path={`${routeProps.match.path}/projectType`} component={AdminProjectTypeList} />
								<Route exact path={`${routeProps.match.path}/service`} component={AdminServiceList} />
								<Route exact path={`${routeProps.match.path}/position`} component={AdminPositionList} />
								<Route exact path={`${routeProps.match.path}/calendarType`} component={AdminCalendarTypeList} />
								<Route exact path={`${routeProps.match.path}/documentType`} component={AdminDocumentTypeList} />
								<Route exact path={`${routeProps.match.path}/receptionType`} component={AdminReceptionTypeList} />
								<Route exact path={`${routeProps.match.path}/knowledgeBaseType`} component={AdminKnowledgeBaseTypeList} />
								<Route exact path={`${routeProps.match.path}/tag`} component={AdminTagList} />
								<Route component={NotFound404} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="/client" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/add`} component={ClientAdd} />
								<Route exact path={`${routeProps.match.path}/edit/:id`} component={ClientEdit} />
								<Route exact path={`${routeProps.match.path}/view/:id`} component={ClientView} />
								<Route component={ClientList} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="/guest" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/add`} component={GuestAdd} />
								<Route exact path={`${routeProps.match.path}/edit/:id`} component={GuestEdit} />
								<Route component={GuestList} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="/package" render={routeProps =>
						<Layout>
							<Switch>
								<Route exact path={`${routeProps.match.path}/add`} component={PackageAdd} />
								<Route exact path={`${routeProps.match.path}/edit/:id`} component={PackageEdit} />
								<Route component={PackageList} />
							</Switch>
						</Layout>}>
					</Route>

					<Route exact path="/calendar" render={routeProps => <Layout><CalendarEventPage/></Layout>} />

					<Route exact path="/notifications" render={routeProps => <Layout><NotificationList/></Layout>} />
					<Route exact path="/push" render={routeProps => <Layout><PushNotification/></Layout>} />

					<Route exact path="/login" render={routeProps => <Layout type="boxOnly"><Login /></Layout>} />

					<Route path="/password" render={routeProps =>
						<Layout type="boxOnly">
							<Switch>
								<Route exact path={`${routeProps.match.path}/recovery`} component={PasswordRecovery} />
								<Route exact path={`${routeProps.match.path}/recoveryChange/:recoveryToken`} component={PasswordRecoveryChange} />
								<Route exact path={`${routeProps.match.path}/recoverySent`} component={PasswordRecoveryMessage} />
								<Route component={NotFound404} />
							</Switch>
						</Layout>}>
					</Route>

					<Route path="*" render={() => <Layout><NotFound404/></Layout>} />
				</Switch>
			</Router>
		</AccessContext.Provider>
	);
}
