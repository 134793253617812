import React from 'react';
import Picker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import locale from 'rc-calendar/lib/locale/pl_PL';
import moment from 'moment';
import 'moment/locale/pl';

export default function RangeDateTimePicker(props) {
	moment.locale('pl');

	const formatDate = 'YYYY-MM-DD';
	const formatTime = 'HH:mm';
	const formatDateTime = formatDate + ' ' + formatTime;

	function handleChange(value) {
		if (typeof props.onChange == 'function') {
			props.onChange(props.name, value);
		}
	}

	function handleBlur() {
		if (typeof props.onBlur == 'function') {
			props.onBlur(props.name);
		}
	}

	function toDate(v) {
		return v ? v.format(formatDate) : '';
	}

	function toTime(v) {
		return v ? v.format(formatTime) : '';
	}

	function isValidRange(v) {
		return v && v[0] && v[1];
	}

	const calendar = (
		<RangeCalendar
			showToday={false}
			showOk={false}
			showWeekNumber={false}
			locale={locale}
			timePicker={<TimePickerPanel format={formatTime} showSecond={false} minuteStep={15} />}
			showTime={{ format: formatTime }}
			format={formatDateTime}
		/>
	);

	return (
		<Picker
			value={props.value}
			showDateInput={false}
			onChange={handleChange}
			calendar={calendar}
		>
			{() => {
				return (<span>
					Od&nbsp;
	                <input
		                type="text"
		                style={{width: 110}}
		                className="noMinimumWidth"
		                readOnly
		                onBlur={handleBlur}
		                value={isValidRange(props.value) && `${toDate(props.value[0])}` || ''}
	                />
					&nbsp;
					<input
						placeholder="--:--"
						type="text"
						style={{width: 70}}
						className="noMinimumWidth"
						readOnly
						onBlur={handleBlur}
						value={isValidRange(props.value) && `${toTime(props.value[0])}` || ''}
					/>
					&nbsp;&nbsp;&nbsp;
					Do&nbsp;
					<input
						type="text"
						style={{width: 110}}
						className="noMinimumWidth"
						readOnly
						onBlur={handleBlur}
						value={isValidRange(props.value) && `${toDate(props.value[1])}` || ''}
					/>
					&nbsp;
					<input
						placeholder="--:--"
						type="text"
						style={{width: 70}}
						className="noMinimumWidth"
						readOnly
						onBlur={handleBlur}
						value={isValidRange(props.value) && `${toTime(props.value[1])}` || ''}
					/>

					{/*<Dump value={props.value}/>*/}
                </span>);
			}
		}
	</Picker>);
}
