import React from 'react';
import {ErrorMessage, FieldArray} from "formik";
import Field from "../form/field";
import {validateProfile} from "../profile/profileForm";
import ClientPerson from "./clientPerson";
import {isEmptyObject} from "../utils";
import SelectProvince from "../form/selectProvince";
import SelectTags from "../form/selectTag";
import ColorPicker from "../component/colorPicker";

export default function ClientForm(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<h3 className="top0 bottom32">Adres świadczenia usług</h3>
			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="name">Nazwa firmy</label>
					<Field className="fullWidth" type="text" name="name"/>
					<ErrorMessage component="div" className="errorMessage" name="name"/>
				</div>
				<div className="formItem column">
					<label htmlFor="phone">Główny numer telefonu</label>
					<Field className="fullWidth" type="tel" name="phone"/>
					<ErrorMessage component="div" className="errorMessage" name="phone"/>
				</div>
				<div className="formItem column">
					<label htmlFor="email">Główny adres e-mail</label>
					<Field className="fullWidth" type="email" name="email"/>
					<ErrorMessage component="div" className="errorMessage" name="email"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="city">Miasto</label>
					<Field className="fullWidth" type="text" name="city"/>
					<ErrorMessage component="div" className="errorMessage" name="city"/>
				</div>
				<div className="formItem column">
					<label htmlFor="postalCode">Kod pocztowy</label>
					<Field className="fullWidth" type="text" name="postalCode"/>
					<ErrorMessage component="div" className="errorMessage" name="postalCode"/>
				</div>
				<div className="formItem column">
					<label htmlFor="street">Ulica</label>
					<Field className="fullWidth" type="text" name="street"/>
					<ErrorMessage component="div" className="errorMessage" name="street"/>
				</div>
				<div className="formItem column">
					<label htmlFor="province">Województwo</label>
					<SelectProvince
						className="fullWidth"
						name="province"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.province}
					/>
					<ErrorMessage component="div" className="errorMessage" name="province"/>
				</div>
			</div>

			<h3 className="top40 bottom32">Dane do faktury</h3>
			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="fvName">Nazwa firmy</label>
					<Field className="fullWidth" type="text" name="fvName"/>
					<ErrorMessage component="div" className="errorMessage" name="fvName"/>
				</div>
				<div className="formItem column">
					<label htmlFor="nip">NIP</label>
					<Field className="fullWidth" type="tel" name="nip"/>
					<ErrorMessage component="div" className="errorMessage" name="nip"/>
				</div>
				<div className="formItem column">
					<label htmlFor="fvPhone">Numer telefonu</label>
					<Field className="fullWidth" type="tel" name="fvPhone"/>
					<ErrorMessage component="div" className="errorMessage" name="fvPhone"/>
				</div>
				<div className="formItem column">
					<label htmlFor="fvEmail">Adres e-mail</label>
					<Field className="fullWidth" type="email" name="fvEmail"/>
					<ErrorMessage component="div" className="errorMessage" name="fvEmail"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="fvCity">Miasto</label>
					<Field className="fullWidth" type="text" name="fvCity"/>
					<ErrorMessage component="div" className="errorMessage" name="fvCity"/>
				</div>
				<div className="formItem column">
					<label htmlFor="fvPostalCode">Kod pocztowy</label>
					<Field className="fullWidth" type="text" name="fvPostalCode"/>
					<ErrorMessage component="div" className="errorMessage" name="fvPostalCode"/>
				</div>
				<div className="formItem column">
					<label htmlFor="fvStreet">Ulica</label>
					<Field className="fullWidth" type="text" name="fvStreet"/>
					<ErrorMessage component="div" className="errorMessage" name="fvStreet"/>
				</div>
				<div className="formItem column">
					<label htmlFor="fvProvince">Województwo</label>
					<SelectProvince
						className="fullWidth"
						name="fvProvince"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.fvProvince}
					/>
					<ErrorMessage component="div" className="errorMessage" name="fvProvince"/>
				</div>
			</div>

			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="tags">Tagi</label>
					<SelectTags
						name="tags"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.tags}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="typeDisabled"/>
				</div>
			</div>

			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="color">Kolor</label>
					<ColorPicker
						width={380}
						color={values.color}
						onChange={(color) => setFieldValue('color', color)}
					/>
					<ErrorMessage component="div" className="errorMessage" name="color"/>
				</div>
			</div>
		</>
	)
}

export function ClientFormNewContact(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	return (
		<FieldArray
			name="newPeople"
			render={(arrayHelpers) => {
				function add(event, arrayHelpers) {
					event.preventDefault();
					arrayHelpers.push({
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						position: "",
					});
				}

				return (
					<span>
						{values.newPeople.map((data, i) => (
							<ClientPerson
								setFieldValue={setFieldValue}
								arrayHelpers={arrayHelpers}
								values={values}
								data={data}
								key={i}
								index={i}
								name="newPeople"
							/>
						))}
						<div className="textCenter bottom32">
							<button className="plus" onClick={(event) => {add(event, arrayHelpers);}}><i className="fas fa-plus"/></button>
							<br/>
							<a href="#" onClick={(event) => {add(event, arrayHelpers);}}>dodaj kolejną osobę kontaktową</a>
						</div>
					</span>
				);
			}}
		/>
	)
}

export function validateClient(values) {
	let errors = {};

	if (!values.name) {
		errors.name = 'Nazwa firmy jest wymagana';
	}
	if (!values.phone) {
		errors.phone = 'Telefon jest wymagany';
	} else if (values.phone.length < 9) {
		errors.phone = 'Telefon musi mieć przynajmniej 9 cyfr';
	}
	if (!values.email) {
		errors.email = 'Adres e-mail jest wymagany';
	}

	errors.newPeople = [];
	values.newPeople.map((item, i) => {
		let result = validateProfile(item);
		if (result !== null && isEmptyObject(result) === false) {
			errors.newPeople[i] = result;
		}
	});
	// remove newPeople error object if all add new person forms passes validation
	if (errors.newPeople.length === 0) {
		delete errors.newPeople;
	}

	return errors;
}