import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../form/field";
import {filenameForLabel, formatLocalizedDate, getFilenameFromPath, isEmptyObject, objectClone} from "../utils";
import {parseISO} from 'date-fns'
import DocumentForm, {validateDocument} from "./documentForm";
import Spinner from "../component/spinner";
import {formikErrorHelper, uploadFileHelper} from "../form/formUtils";
import {Helmet} from 'react-helmet-async';

export default function DocumentEdit(props) {

	const [document, setDocument] = useState({});

	async function fetchDocument() {
		const response = await axios.get(API_URL + '/document/' + props.documentId);
		if (response.data.data.user) {
			setDocument(response.data.data.user);
		}
	}

	useEffect(() => {
		fetchDocument();
	}, [props.documentId]);

	function handleSubmit(values, bag) {

		function save(toSave) {
			axios.patch(API_URL + '/document/' + props.documentId, toSave)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		}

		let toSave = {};
		Object.keys(values).map(function(item) {
			if (document[item] !== values[item]) {
				toSave[item] = values[item];
			}
		});

		if (toSave.hasOwnProperty('type')) {
			toSave['type'] = parseInt(toSave['type']);
		}
		if (toSave.hasOwnProperty('status')) {
			toSave['status'] = parseInt(toSave['status']);
		}

		//console.log(toSave);

		if (values.file instanceof File) {
			uploadFileHelper(values.file, function(uploadedFileId) {
				toSave.file = uploadedFileId;
				save(toSave);
			});
		} else {
			save(toSave);
		}
	}

	return (isEmptyObject(document) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(document);
		delete initial.id;
		initial.modified = parseISO(initial.modified);

		if (initial.hasOwnProperty('client') && initial.client.hasOwnProperty('id')) {
			initial.client = initial.client.id;
		} else {
			initial.client = null;
		}

		if (initial.hasOwnProperty('type') && initial.type.hasOwnProperty('id')) {
			initial.type = initial.type.id;
		} else {
			initial.type = null;
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateDocument}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<>
							<Helmet>
								<title>{"Edycja "  + getFilenameFromPath(values.file.path) + ' - ' + APP_NAME}</title>
							</Helmet>
							<Form>
								<div className="rcModelContents">
									<h3 className="top0 bottom24">Edycja {getFilenameFromPath(values.file.path)}</h3>

									<div className="row with four columns">
										<DocumentForm {...propsFormik}/>

										<div className="formItem column">
											<label>Data ostatniej zmiany</label>
											<input type="text" readOnly value={formatLocalizedDate(values.modified)}/>
										</div>

										<div className="formItem column">
											<label>Przez kogo</label>
											<input type="text" readOnly value={values.user.name}/>
										</div>
									</div>

									<div className="formItem">
										<label htmlFor="description">Opis dokumentu</label>
										<Field className="fullWidth" component="textarea" name="description" rows="4"/>
										<ErrorMessage component="div" className="errorMessage" name="description"/>
									</div>

									<div className="formItem">
										<label>Załączony plik</label>

										<input type="text" value={filenameForLabel(values.file)} readOnly className="right24"/>
										<input id="file" name="file" type="file" onChange={function(event) {
											setFieldValue("file", event.currentTarget.files[0]);
										}} />
										<label htmlFor="file">Dodaj plik</label>
									</div>

									{status && <div className="errorMessage">{status}</div>}

									{/*<Dump value={initial}/>*/}
									{/*<Dump value={propsFormik.values} />*/}

									<div className="rcModalActions">
										<button type="submit" className="left32" disabled={isSubmitting}>Zapisz</button>
									</div>
								</div>
							</Form>
						</>
					)
				}}
			</Formik>
		);
	}
}
