import React from "react"
import {Link} from "react-router-dom"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import PillName from "../component/pillName";
import {confirmDelete, formatLocalizedDate} from "../utils";
import {parseISO} from "date-fns";

export default function PackageRow(props) {
	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/reception/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function getCompanyName(company = '', id = null) {
		let shortName = company.split(' ');
		shortName = shortName.map(function(item) {
			return item.substring(0, 1);
		});
		return shortName.join('');
	}

	return (
		<div className="boxRow">
			<div className="boxCell textBold">
				<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
					{item.description}
				</a>
			</div>
			<div className="boxCell">{item.type ? item.type.name : ''}</div>
			<div className="boxCell">{item.client ? item.client.name : item.clientName}</div>
			<div className="boxCell">{item.company ? <PillName value={{name: getCompanyName(item.company.name), color: item.company.color}}/> : ''}</div>
			<div className="boxCell">{formatLocalizedDate(parseISO(item.operationDate))}</div>
			<div className="boxCell">{item.taskNumber}</div>
			<div className="boxCell">{item.sysNumber}</div>
			<div className="boxCell textRight">
				<PopupMenu style={{right: '-12px'}}>
					<li onClick={() => props.handleOpenModalView(item.id)}>Podgląd</li>
					<li><Link to={"/package/edit/" + item.id}>Edytuj</Link></li>
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			</div>
		</div>
	);
}