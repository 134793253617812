import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import SelectDepartment from "../form/selectDepartment";
import SelectKnowledgeType from "../form/selectKnowledgeType";
import {formatLocalizedDate} from "../utils";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";

export default function KnowledgeForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="name">Nazwa</label>
					<Field className="fullWidth" type="text" name="name"/>
					<ErrorMessage component="div" className="errorMessage" name="name"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="type">Typ</label>
					<SelectKnowledgeType
						name="type"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.type}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="type"/>
				</div>
				<div className="formItem column">
					<label htmlFor="department">Przypisz do działu</label>
					<SelectDepartment
						name="department"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.department}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="department"/>
				</div>
				{props.showFieldsModified ?
				<>
					<div className="formItem column">
						<label>Data ostatniej zmiany</label>
						<input type="text" readOnly value={formatLocalizedDate(parseISO(props.knowledge.modified))} className="fullWidth"/>
					</div>
					<div className="formItem column">
						<label>Przez kogo</label>
						<input type="text" readOnly value={props.knowledge.userModified ? props.knowledge.userModified.name : ''} className="fullWidth"/>
					</div>
				</>: ''}
			</div>

			<div className="formItem">
				<label htmlFor="description">Opis</label>
				<Field className="fullWidth" component="textarea" name="description" rows="4"/>
				<ErrorMessage component="div" className="errorMessage" name="description"/>
			</div>

			<div className="row with four columns">
				{values.files && values.files.map(function(file, index) {
					return (
						<div className="formItem column" key={file.id}>
							{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					);
				})}
				{props.uploadedFiles.map(function(file) {
					return (
						<div className="formItem column" key={file.id}>
							<label>&nbsp;</label>
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					)
				})}
			</div>

			<FileUpload handleFile={props.handleFileAdd}/>

			{status && <div className="errorMessage">{status}</div>}

			{/*<Dump value={initial}/>*/}
			{/*{<Dump value={values} />}*/}
		</>
	)
}

export function validateKnowledge(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.type) {
		errors.type = 'Typ jest wymagany';
	}
	if (!values.department) {
		errors.department = 'Dział jest wymagany';
	}
	return errors;
}
