import React from "react"
import PopupMenu from "../../component/popupMenu";
import {parseISO} from 'date-fns'
import {confirmDelete, formatLocalizedDate} from "../../utils";
import axios from "axios";
import PillName from "../../component/pillName";

export default function AdminTaskRow(props) {

	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/definition/task/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	return (
		<div className="boxRow">
			<div className="boxCell">
				<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
					{item.name}
				</a>
			</div>
			<div className="boxCell"><PillName value={item.department}/></div>
			<div className="boxCell">{item.procedure}</div>
			<div className="boxCell">{formatLocalizedDate(parseISO(item.modified))}</div>
			<div className="boxCell">{item.userModified ? item.userModified.name : ''}</div>

			<div className="boxCell textRight padRight8">
				<PopupMenu>
					<li onClick={() => props.handleOpenModalView(item.id)}>Zobacz</li>
					<li onClick={() => props.handleOpenModalEdit(item.id)}>Edytuj</li>
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			</div>
		</div>
	);
}