import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {findIndexOfObjectWithPropertyValueInArray, formatLocalizedDate, formatLocalizedDateTime, isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";
import CommentView from "../component/commentView";
import CommentAdd from "../component/commentAdd";
import FileUpload from "../component/fileUpload";
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../form/field";
import {formikErrorHelper} from "../form/formUtils";
import SelectIssueStatus from "../form/selectIssueStatus";
import DatePicker from "../form/datePicker";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {actionFetchProfile} from "../state/actionsProfile";
import {arrayPeopleToNameString} from "./utilIssue";

export default function YourIssueView(props) {
	const dispatch = useDispatch();

	const me = useSelector(state => state.profile.me);
	const meLoading = useSelector(state => state.profile.loading);

	useEffect(() => {
		if (meLoading === false && isEmptyObject(me)) {
			dispatch(actionFetchProfile());
		}
	}, []);

	const [issue, setIssue] = useState({});

	async function fetchIssue() {
		const response = await axios(API_URL + '/issue/' + props.issueId);
		//console.log(response.data.data);
		if (response.data.data.user) {
			let foo = objectClone(response.data.data.user);

			if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('name')) {
				foo.client = foo.client.name;
			}

			if (foo.hasOwnProperty('orderedBy') && foo.orderedBy.hasOwnProperty('name')) {
				foo.orderedByFull = foo.orderedBy;
				foo.orderedBy = foo.orderedBy.name;
			}

			if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('name')) {
				foo.user = foo.user.name;
			}

			if (foo.hasOwnProperty('status') && foo.status.hasOwnProperty('id')) {
				foo.status = foo.status.id;
			}

			if (foo.hasOwnProperty('timeRequired')) {
				foo.timeRequired = parseInt(foo.timeRequired);
			}

			setIssue(foo);
		}
	}

	useEffect(() => {
		fetchIssue();
	}, [props.issueId]);

	function handleFileAdd(uploadedFile) {
		axios.post(API_URL + '/issue/file/' + props.issueId + '/' + uploadedFile.id)
			.then(function(response) {
				fetchIssue();
			});
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(issue.files, fileId);
		if (index !== null && index !== -1) {
			axios.delete(API_URL + '/issue/file/' + props.issueId + '/' + fileId)
				.then(function(response) {
					fetchIssue();
				});
		}
	}

	function handleCommentAdded(newCommentId) {
		axios.post(API_URL + '/issue/comment/' + props.issueId + '/' + newCommentId)
			.then(function(response) {
				fetchIssue();
			});
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (issue[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		if (toSend.hasOwnProperty('statusHistory')) {
			delete toSend.statusHistory;
		}

		if (toSend.hasOwnProperty('orderedByFull')) {
			delete toSend.orderedByFull;
		}

		if (toSend.hasOwnProperty('status')) {
			toSend['status'] = parseInt(toSend['status']);
		}

		axios.patch(API_URL + '/issue/' + props.issueId, toSend)
			.then(function(response) {
				bag.setSubmitting(false);

				if (typeof props.handleRefresh === 'function') {
					props.handleRefresh();
				}
				if (typeof props.handleClose === 'function') {
					props.handleClose();
				}
			}).catch(function(error) {
			formikErrorHelper(error, bag);
		});
	}


	function getFinishedDate() {
		let array = [...issue.statusHistory];

		if (array && Array.isArray(array) && array.length > 0) {
			let entry = array[0];
			if (entry && entry.hasOwnProperty('status') && (entry.status === 9 || entry.status === 10)) {
				return entry.created;
			}
		}

		return null;
	}

	function canShowAcceptedStatus() {
		let currentUserId = me ? me.id : null;
		let issueOwnedByUserId = issue.orderedByFull ? issue.orderedByFull.id : null;

		return (currentUserId === issueOwnedByUserId && currentUserId !== null);
	}

	return (isEmptyObject(issue) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		let initial = objectClone(issue);

		delete initial.id;
		delete initial.files;
		delete initial.comments;

		initial.dateRealization = initial.dateRealization ? moment(initial.dateRealization) : null;
		initial.dateRealizationEstimation = initial.dateRealizationEstimation ? moment(initial.dateRealizationEstimation) : null;

		return (
			<Formik
				initialValues={initial}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<div className="rcModelContents">
							<Form>
								<h3 className="top0 bottom24">{issue.name}</h3>

								<div className="row with four columns">
									<div className="formItem column">
										<label>Osoba zlecająca</label>
										<input type="text" readOnly value={issue.orderedBy} className="fullWidth"/>
									</div>

									<div className="formItem column">
										<label>Klient</label>
										<input type="text" readOnly value={issue.client} className="fullWidth"/>
									</div>

									<div className="formItem column">
										<label>Graniczna data zakończenia</label>
										<input type="text" readOnly value={formatLocalizedDate(parseISO(issue.dateRealization))} className="fullWidth"/>
									</div>

									<div className="formItem column">
										<label htmlFor="status">Status</label>
										<SelectIssueStatus
											className="fullWidth"
											name="status"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.status}
											showAcceptStatus={canShowAcceptedStatus()}
										/>
										<ErrorMessage component="div" className="errorMessage" name="status"/>
									</div>
								</div>

								<div className="formItem">
									<label>Przydzielono do</label>
									<input type="text" readOnly value={arrayPeopleToNameString(issue.users)} className="fullWidth"/>
								</div>

								<div className="row with four columns">
									<div className="formItem column">
										<label>Data przydzielenia</label>
										<input type="text" readOnly value={formatLocalizedDateTime(parseISO(issue.assignDate))} className="fullWidth"/>
									</div>

									<div className="formItem column">
										<label htmlFor="dateRealization">Prognozowany termin wykonania</label>
										<DatePicker
											className="fullWidth"
											name="dateRealizationEstimation"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateRealizationEstimation}
										/>
									</div>

									<div className="formItem column">
										<label htmlFor="timeRequired">Wymagany czas</label>
										<Field
											className="noMinimumWidth"
											name="timeRequired"
											type="number"
											style={{width: 100}}
											value={values.timeRequired}
										/>
										&nbsp;&nbsp;godz.
									</div>

									{!getFinishedDate() ? '' :
										<div className="formItem column">
											<label htmlFor="dateRealizationLimit">Faktyczna data zakończenia</label>
											<input className="fullWidth" type="text" readOnly value={formatLocalizedDateTime(parseISO(getFinishedDate()))}/>
										</div>
									}
								</div>

								<div className="formItem">
									<label htmlFor="description">Opis</label>
									<textarea className="fullWidth" name="description" value={issue.description} rows="4" readOnly/>
								</div>

								<h4>Komentarze</h4>
								{issue.comments && issue.comments.map(function(item) {
									return <CommentView
										commentId={item.id}
										text={item.text}
										date={item.created}
										poster={item.createdBy ? item.createdBy.name : ''}
										posterId={item.createdBy ? item.createdBy.id : ''}
										refresh={fetchIssue}
										key={"comment" + item.id}
									/>;
								})}
								<CommentAdd handleAdd={handleCommentAdded}/>

								<div className="row with four columns">
									{issue.files && issue.files.map(function(file, index) {
										return (
											<div className="formItem column" key={file.id}>
												{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
												<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id)}/>
											</div>
										);
									})}
								</div>

								<FileUpload handleFile={handleFileAdd}/>

								{/*<Dump value={initial}/>*/}
								{/*<Dump value={values}/>*/}

								{status && <div className="errorMessage">{status}</div>}

								<div className="rcModalActions">
									<button type="submit" disabled={isSubmitting} className="withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
		);
	}
}
