import React, {useEffect} from 'react';
import {Form, Formik} from "formik";
import CheckBox from "../form/checkBox";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		taskStatusesLoading: state.taskStatuses.loading,
	};
};

function FilterTask(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.taskStatusesLoading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}
	}, []);

	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				function handleMultipleCheckboxChange(name, value) {
					let newValues = [];
					if (values.hasOwnProperty(name) && Array.isArray(values[name])) {
						newValues = [...values[name]];
					}
					if (newValues.indexOf(value) === -1) {
						newValues.push(value);
					} else {
						newValues.splice(newValues.indexOf(value), 1);
					}
					setFieldValue(name, newValues);
				}

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>

							{props.taskStatuses && props.taskStatuses.map(function(item) {
								return (
									<div className="displayInlineBlock right16 bottom20" key={"status" + item.name}>
										<CheckBox
											name="status"
											defaultChecked={false}
											value={item}
											checked={values.status && values.status.includes(item)}
											onChange={handleMultipleCheckboxChange}
											onBlur={setFieldTouched}
										>
											{item.name}
										</CheckBox>
									</div>
								);
							})}

							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}

export default connect(mapStateToProps)(FilterTask);