import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";
import DatePicker from "../form/datePicker";
import SelectPerson from "../form/selectPerson";
import SelectClient from "../form/selectClient";

export default function IssueForm(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="name">Nazwa</label>
					<Field className="fullWidth" type="text" name="name"/>
					<ErrorMessage component="div" className="errorMessage" name="name"/>
				</div>
				<div className="column">
					<div className="row with two columns">
						<div className="formItem column">
							<label htmlFor="dateRealization">Graniczna data zakończenia</label>
							<DatePicker
								name="dateRealization"
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								value={values.dateRealization}
								className="fullWidth"
							/>
							<ErrorMessage component="div" className="errorMessage" name="dateRealization"/>
						</div>

						<div className="formItem column">
							<label htmlFor="type">Klient</label>
							<SelectClient
								name="client"
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								value={values.client}
								className="fullWidth"
								allowClear={true}
							/>
							<ErrorMessage component="div" className="errorMessage" name="client"/>
						</div>
					</div>
				</div>
			</div>

			<div className="formItem">
				<label htmlFor="users">Przypisz do</label>
				<SelectPerson
					mode="multiple"
					name="users"
					value={values.users}
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					className="fullWidth"
				/>
				<ErrorMessage component="div" className="errorMessage" name="users"/>
			</div>

			<div className="formItem">
				<label htmlFor="description">Opis</label>
				<Field className="fullWidth" component="textarea" name="description" rows="4"/>
				<ErrorMessage component="div" className="errorMessage" name="description"/>
			</div>

			<div className="row with four columns">
				{values.files && values.files.map(function(file, index) {
					return (
						<div className="formItem column" key={file.id}>
							{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					);
				})}
				{props.uploadedFiles.map(function(file) {
					return (
						<div className="formItem column" key={file.id}>
							<label>&nbsp;</label>
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					)
				})}
			</div>

			<FileUpload handleFile={props.handleFileAdd}/>

			{status && <div className="errorMessage">{status}</div>}

			{/*<Dump value={initial}/>*/}
			{/*{<Dump value={values} />}*/}
		</>
	)
}

export function validateIssue(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.users || values.users.length === 0) {
		errors.users = 'Użytkownik jest wymagany';
	}
	if (!values.description) {
		errors.description = 'Opis jest wymagany';
	}
	return errors;
}
