import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../../form/field";
import {findObjectWithPropertyValueInArray, findIndexOfObjectWithPropertyValueInArray, formatLocalizedDate, isEmptyObject, objectClone} from "../../utils";
import {validateProcedure} from "./adminProcedureForm";
import Spinner from "../../component/spinner";
import SelectDepartment from "../../form/selectDepartment";
import SelectServices from "../../form/selectService";
import {formikErrorHelper} from "../../form/formUtils";
import ReactModal from "react-modal";
import SelectPopupTask from "../../popupSelect/selectPopupTask";
import {useDispatch, useSelector} from "react-redux";
import PopupMenu from "../../component/popupMenu";
import {actionFetchTasks} from "../../state/actionsTasks";
import FileView from "../../component/fileView";
import FileUpload from "../../component/fileUpload";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

export default function AdminProcedureEdit(props) {
	const dispatch = useDispatch();

	const tasks = useSelector(state => state.tasks.list);
	const tasksLoading = useSelector(state => state.tasks.loading);

	const [procedure, setProcedure] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [tasksToAdd, setTasksToAdd] = useState([]);
	const [tasksToDelete, setTasksToDelete] = useState([]);
	const [taskOrder, setTaskOrder] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const DragHandle = sortableHandle(() => <span style={{cursor: "grab"}} className="right12"><i className="fas fa-grip-lines"/></span>);

	const SortableItem = sortableElement(({value}) => {
		return (
			<div className="boxItem small bottom12" key={"task" + value.id}>
				<DragHandle />
				{value.name}
				<PopupMenu>
					<li onClick={() => {handleTaskDelete(value.id)}} className="red">Usuń</li>
				</PopupMenu>
			</div>
		);
	});

	const SortableContainer = sortableContainer(({children}) => {
		return <span>{children}</span>;
	});

	useEffect(() => {
		if (tasksLoading === false && (tasks == null || tasks.length === 0)) {
			dispatch(actionFetchTasks());
		}
	}, []);

	useEffect(() => {
		async function fetchProcedure() {
			const response = await axios(API_URL + '/definition/procedure/' + props.procedureId);
			//console.log(response.data.data);
			if (response.data.data.procedure) {
				setProcedure(response.data.data.procedure);
			}
		}

		fetchProcedure();
	}, [props.procedureId]);

	useEffect(() => {
		let taskOrder = [];
		let sortedTasks = [];

		if (procedure.tasks) {
			sortedTasks = [...procedure.tasks];

			sortedTasks.sort(function(a, b) {
				if (a.position > b.position) {
					return 1;
				}
				if (a.position < b.position) {
					return -1;
				}
				return 0;
			});
		}

		sortedTasks.map(function(task) {
			taskOrder.push({
				id: task.id,
				name: task.name
			})
		});

		setTaskOrder(taskOrder);
	}, [procedure]);

	function getOrderArray() {
		let toReturn = [];
		let position = 1;

		taskOrder.map(function(item) {
			toReturn[item.id] = position++;
		});

		return toReturn;
	}

	const onSortEnd = ({oldIndex, newIndex}) => {
		setTaskOrder(arrayMove(taskOrder, oldIndex, newIndex));
	};

	function handleOpenModal() {
		setShowModal(true);
	}

	function handleNewTask(newId) {
		if (Number.isInteger(newId) && tasksToAdd.indexOf(newId) === -1 && findIndexOfObjectWithPropertyValueInArray(procedure.tasks, newId) === -1) {
			setTasksToAdd([newId, ...tasksToAdd]);

			let item = findObjectWithPropertyValueInArray(tasks, newId);

			if (typeof item === "object" && item != null) {
				setTaskOrder([...taskOrder, {
					id: item.id,
					name: item.name
				}]);
			}
		}

		setShowModal(false);
	}

	function handleTaskDelete(id) {
		if (tasksToAdd.indexOf(id) !== -1) {
			setTasksToAdd(tasksToAdd.filter(function(value) { return value !== id; }));
		} else if (tasksToDelete.indexOf(id) === -1) {
			setTasksToDelete([...tasksToDelete, id]);
		}

		let indexOrder = findIndexOfObjectWithPropertyValueInArray(taskOrder, id);
		if (indexOrder !== -1) {
			let newTaskOrder = [...taskOrder];
			newTaskOrder.splice(indexOrder, 1);
			setTaskOrder(newTaskOrder);
		}
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/definition/procedure/file/' + procedure.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, procedureId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/procedure/file/' + procedureId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function addTasks(tasksToAdd, procedureId) {
		let promisesArray = [];

		if (tasksToAdd && Array.isArray(tasksToAdd) && tasksToAdd.length > 0) {
			tasksToAdd.map((taskId) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/procedure/task/' + procedureId + '/' + taskId)
				);
			});
		}

		return promisesArray;
	}

	function deleteTasks(tasksToDelete, procedureId) {
		let promisesArray = [];

		if (tasksToDelete && Array.isArray(tasksToDelete) && tasksToDelete.length > 0) {
			tasksToDelete.map((taskId) => {
				promisesArray.push(
					axios.delete(API_URL + '/definition/procedure/task/' + procedureId + '/' + taskId)
				);
			});
		}
		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (procedure[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		if (toSend.services) {
			toSend.services = [toSend.services];
		}

		delete toSend.userModified;
		delete toSend.files;

		if (toSend.hasOwnProperty('type')) {
			toSend['type'] = parseInt(toSend['type']);
		}
		if (toSend.hasOwnProperty('department')) {
			toSend['department'] = parseInt(toSend['department']);
		}

		let promises = [];
		promises.concat(addTasks(tasksToAdd, props.procedureId));
		promises.concat(deleteTasks(tasksToDelete, props.procedureId));
		promises.concat(addFiles(uploadedFiles, props.procedureId));

		Promise.all(promises).then(() => {
			axios.post(API_URL + '/definition/procedure/task/sort/' + props.procedureId, {
				id: props.procedureId,
				order: getOrderArray()
			}).then(() => {
				axios.patch(API_URL + '/definition/procedure/' + props.procedureId, toSend)
					.then(function(response) {
						bag.setSubmitting(false);
						//console.log(response);
						if (typeof props.handleRefresh === 'function') {
							props.handleRefresh();
						}
						if (typeof props.handleClose === 'function') {
							props.handleClose();
						}
					}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
			})
		});
	}

	return (isEmptyObject(procedure) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(procedure);
		delete initial.id;
		delete initial.created;
		delete initial.modified;

		if (Array.isArray(initial.services) && initial.services.length > 0) {
			initial.services = initial.services[0].id;
		}

		if (initial.hasOwnProperty('type') && initial.type.hasOwnProperty('id')) {
			initial.type = initial.type.id;
		}

		if (initial.hasOwnProperty('department') && initial.department.hasOwnProperty('id')) {
			initial.department = initial.department.id;
		}

		if (initial.hasOwnProperty('userModified') && initial.userModified.hasOwnProperty('name')) {
			initial.userModified = initial.userModified.name;
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateProcedure}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<>
							<Form>
								<div className="rcModelContents">
									<h3 className="top0 bottom24">Edycja Procedury</h3>
									<div className="formItem">
										<Field className="fullWidth" type="text" name="name" style={{ fontSize: '18px'}}/>
									</div>

									<div className="row with four columns">
										<div className="formItem column">
											<label htmlFor="services">Przypisanie do usługi</label>
											<SelectServices
												name="services"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												value={values.services}
												className="fullWidth"
											/>
											<ErrorMessage component="div" className="errorMessage" name="services"/>
										</div>
										<div className="formItem column">
											<label htmlFor="department">Przypisz do działu</label>
											<SelectDepartment
												name="department"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												value={values.department}
												className="fullWidth"
											/>
											<ErrorMessage component="div" className="errorMessage" name="department"/>
										</div>

										<div className="column">
											<div className="formItem">
												<label>Data ostatniej zmiany</label>
												<input type="text" readOnly value={formatLocalizedDate(values.modified)} className="fullWidth"/>
											</div>
										</div>
										<div className="column">
											<div className="formItem">
												<label>Przez kogo</label>
												<input type="text" readOnly value={values.userModified} className="fullWidth"/>
											</div>
										</div>
									</div>

									<div className="formItem">
										<label htmlFor="description">Opis procedury</label>
										<Field className="fullWidth" component="textarea" name="description" placeholder="Wpisz opis&hellip;" rows="4"/>
										<ErrorMessage component="div" className="errorMessage" name="description"/>
									</div>

									<div className="row with four columns">
										{values.files && values.files.map(function(file, index) {
											return (
												<div className="formItem column" key={file.id}>
													{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
													<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, values.files, setFieldValue)}/>
												</div>
											);
										})}
										{uploadedFiles.map(function(file) {
											return (
												<div className="formItem column" key={file.id}>
													<label>&nbsp;</label>
													<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, values.files, setFieldValue)}/>
												</div>
											)
										})}
									</div>

									<FileUpload handleFile={handleFileAdd}/>

									<div className="formItem bottom0">
										<label>Zadania w procedurze</label>
									</div>

									<SortableContainer onSortEnd={onSortEnd} helperClass='sortableHelper' useDragHandle>
										{taskOrder.map((item, index) => {
											return (
												<SortableItem key={`item-${item.id}`} index={index} value={item}/>
											);
										})}
									</SortableContainer>

									<div className="textCenter bottom12">
										<button className="plus right4" onClick={(event) => {event.preventDefault(); handleOpenModal(); }}>
											<i className="fas fa-plus"/>
										</button>
										<a href="#" onClick={(event) => {event.preventDefault(); handleOpenModal(); }}>
											dodaj zadanie
										</a>
									</div>

									{/*<Dump value={taskOrder}/>*/}
									{/*<Dump value={getOrderArray()}/>*/}
									{/*/!*<Dump value={initial}/>*!/*/}
									{/*<Dump value={tasksToAdd} label="add" />*/}
									{/*<Dump value={tasksToDelete} label="delete" />*/}

									{status && <div className="errorMessage">{status}</div>}

									<div className="rcModalActions">
										<button type="submit" disabled={isSubmitting}>Zapisz</button>
									</div>
								</div>
							</Form>

							<ReactModal
								isOpen={showModal}
								contentLabel=""
								onRequestClose={handleNewTask}
								shouldCloseOnOverlayClick={true}
								className="rcModal withActions"
								overlayClassName="rcOverlay"
							>
								<span onClick={handleNewTask} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

								<SelectPopupTask handleClose={handleNewTask}/>
							</ReactModal>
						</>
					)
				}}
			</Formik>
		);
	}
}
