import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import BackButton from "../component/backButton";
import {isEmptyObject} from "../utils";
import axios from "axios";
import Spinner from "../component/spinner";
import Tag from "../component/tag";
import Sortable from "../component/sortable";
import ProjectRow from "../project/projectRow";
import ReactModal from "react-modal";
import ProjectView from "../project/projectView";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import Avatar from "../component/avatar";
import {AccessContext} from "../access";
import DocumentRow from "../document/documentRow";
import DocumentEdit from "../document/documentEdit";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		loading: state.taskStatuses.loading,
	};
};

function ClientView(props) {
	const dispatch = useDispatch();
	const access = useContext(AccessContext);

	let { id } = useParams();

	const [client, setClient] = useState({});

	const [projects, setProjects] = useState(null);
	const [projectId, setProjectId] = useState(null);
	const [orderProjectColumn, setOrderProjectColumn] = useState('modifiedDate');
	const [orderProjectDirection, setOrderProjectDirection] = useState(true);
	const [showProjectModalView, setShowProjectModalView] = useState(false);

	const [documents, setDocuments] = useState(null);
	const [documentId, setDocumentId] = useState(null);
	const [orderDocumentColumn, setOrderDocumentColumn] = useState('modifiedDate');
	const [orderDocumentDirection, setOrderDocumentDirection] = useState(true);
	const [showDocumentModalEdit, setShowDocumentModalEdit] = useState(false);

	async function fetchClient() {
		const response = await axios(API_URL + '/client/' + id);
		//console.log(response);
		if (response.data.data.client) {
			setClient(response.data.data.client);
		}
	}

	useEffect(() => {
		fetchClient();
	}, [id]);

	useEffect(() => {
		if (props.loading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}
	}, []);

	async function fetchProjects() {
		if (client && client.hasOwnProperty('id')) {

			let params = {'filter[client]': client.id};

			if (orderProjectColumn && orderProjectDirection !== null) {
				params.sort = orderProjectColumn;
				if (orderProjectDirection === true) {
					params.order = 'desc';
				}
				if (orderProjectDirection === false) {
					params.order = 'asc';
				}
			}

			const response = await axios.get(API_URL + '/project/list', {params: params});

			let data = response.data.data.list;
			if (Array.isArray(data.list)) {
				setProjects(data.list);
			}
		}
	}

	useEffect(() => {
		if (access.manager === true) {
			fetchProjects();
		}
	}, [orderProjectDirection, orderProjectColumn, client.id]);

	async function fetchDocuments() {
		if (client && client.hasOwnProperty('id')) {

			let toSend = {'filter[client]': client.id};

			if (orderDocumentColumn && orderDocumentDirection !== null) {
				toSend.sort = orderDocumentColumn;
				if (orderDocumentDirection === true) {
					toSend.order = 'desc';
				}
				if (orderDocumentDirection === false) {
					toSend.order = 'asc';
				}
			}

			const response = await axios.get(API_URL + '/document/list', {params: toSend});

			let data = response.data.data;
			if (Array.isArray(data.list)) {
				setDocuments(data.list);
			}
		}
	}

	useEffect(() => {
		fetchDocuments();
	}, [orderDocumentDirection, orderDocumentColumn, client.id]);

	function handleProjectSortChange(column, direction) {
		setOrderProjectDirection(direction);
		setOrderProjectColumn(column);
	}

	function handleDocumentSortChange(column, direction) {
		setOrderDocumentDirection(direction);
		setOrderDocumentColumn(column);
	}

	function handleCloseProjectModalView() {
		setShowProjectModalView(false);
	}

	function handleCloseDocumentModalView() {
		setShowDocumentModalEdit(false);
	}

	function handleOpenProjectModalView(id) {
		setProjectId(id);
		setShowProjectModalView(true);
	}

	function handleOpenDocumentModalView(id) {
		setDocumentId(id);
		setShowDocumentModalEdit(true);
	}

	return (isEmptyObject(client) ? <div className="textCenter"><Spinner/></div> : render());

	function render() {
		return (
			<div className="clearFix">
				<div className="headerWithButtons">
					<h1>
						{client.name}&nbsp;&nbsp;&nbsp;
						{client.tags && client.tags.map(function(tag) {
							return <Tag className="tag valignBottom" key={"tag" + tag.id}>#{tag.name}</Tag>
						})}
					</h1>
					<div className="right">
						<BackButton>Powrót</BackButton>
						<Link className="left32 button withChevron" to={"/client/edit/" + client.id}>
							Edytuj&nbsp;<i className="fas fa-chevron-right"/>
						</Link>
					</div>
				</div>

				<div className="floatRight" style={{width: '260px'}}>
					<div className="box">
						<h4 className="top0 bottom12">Dane firmy</h4>
						<p>
							{client.name}<br/>
							NIP: {client.nip}<br/>
						</p>

						<h4 className="bottom12">Adres świadczenia usług</h4>
						<p>
							{client.street}<br/>
							{client.postalCode}, {client.city}<br/>
							{client.province ? client.province.name : ''}<br/>
							{(client.phone) ? (<><a href={"tel:" + client.phone}>{client.phone}</a><br/></>) : null}
							<a href={'mailto:' + client.email}>{client.email}</a>
						</p>

						<h4 className="bottom12">Dane do faktury</h4>
						<p>
							{client.fvName}<br/>
							{client.fvStreet}<br/>
							{client.fvPostalCode}, {client.fvCity}<br/>
							{client.fvProvince ? client.fvProvince.name : ''}<br/>
							<a href={'mailto:' + client.fvEmail}>{client.fvEmail}</a>
						</p>
					</div>

					<div className="box">
						<h4 className="top0 bottom24">Osoby kontaktowe</h4>

						{client.persons && client.persons.map((person) => {
							return (
								<p key={"person" + person.id}>
									<b>{person.firstName} {person.lastName}</b><br/>
									{person.position && person.position.name}<br/>
									{(person.phone) ? (<><a href={"tel:" + person.phone}>{person.phone}</a><br/></>) : null}
									<a href={'mailto:' + person.email}>{person.email}</a><br/>
									{person.description}
								</p>
							);
						})}
					</div>
				</div>

				<div style={{marginRight: '290px'}}>
					{client.clientService && client.clientService.length > 0 ? <h3 className="top0 bottom24">Opiekunowie</h3> : null}

					{client.clientService && client.clientService.map(function(person) {
						return (
							<div key={"person" + person.id} className="box smallerPadding">
								<Avatar picture={person.picture} cssClass="noShadow right4"/>
								{person.name}
							</div>
						);
					})}

					{access.manager === false ? '' : (
						<>
							<h4>Projekty</h4>
							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="name" value={(orderProjectColumn === 'name') ? orderProjectDirection : null}>Nazwa Projektu</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="type" value={(orderProjectColumn === 'type') ? orderProjectDirection : null}>Typ</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="client" value={(orderProjectColumn === 'client') ? orderProjectDirection : null}>Klient</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="company" value={(orderProjectColumn === 'company') ? orderProjectDirection : null}>Spółka</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="modifiedDate" value={(orderProjectColumn === 'modifiedDate') ? orderProjectDirection : null}>Data aktualizacji</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleProjectSortChange} index="modifiedBy" value={(orderProjectColumn === 'modifiedBy') ? orderProjectDirection : null}>Przez kogo</Sortable></div>
									<div className="boxCell">Status</div>
									<div className="boxCell"/>
								</div>

								{projects && projects.map(function(item, index) {
									return [
										<ProjectRow
											key={'project' + index}
											value={item}
											handleOpenModalView={handleOpenProjectModalView}
											refresh={fetchProjects}
											taskStatuses={props.taskStatuses}
										/>,
										<div key={'spacer' + index} className="boxRow spacer"/>
									];
								})}
							</div>

							{(projects == null) ? <div className="textCenter"><Spinner/></div> : ''}
						</>
					)}

					<h4>Dokumenty</h4>
					<div className="boxTable">
						<div className="boxHeader">
							<div className="boxCell"><Sortable onChange={handleDocumentSortChange} index="filename" value={(orderDocumentColumn === 'filename') ? orderDocumentDirection : null}>Nazwa pliku</Sortable></div>
							<div className="boxCell"><Sortable onChange={handleDocumentSortChange} index="type" value={(orderDocumentColumn === 'type') ? orderDocumentDirection : null}>Rodzaj dokumentu</Sortable></div>
							<div className="boxCell"><Sortable onChange={handleDocumentSortChange} index="client" value={(orderDocumentColumn === 'client') ? orderDocumentDirection : null}>Nazwa klienta</Sortable></div>
							<div className="boxCell"><Sortable onChange={handleDocumentSortChange} index="modifiedDate" value={(orderDocumentColumn === 'modifiedDate') ? orderDocumentDirection : null}>Data aktualizacji</Sortable></div>
							<div className="boxCell"><Sortable onChange={handleDocumentSortChange} index="modifiedBy" value={(orderDocumentColumn === 'modifiedBy') ? orderDocumentDirection : null}>Przez kogo</Sortable></div>
							<div className="boxCell"/>
						</div>

						{documents && documents.map(function(item, index) {
							return [
								<DocumentRow
									key={'document' + index}
									value={item}
									handleOpenModalEdit={handleOpenDocumentModalView}
									refresh={fetchDocuments}
								/>,
								<div key={'spacer' + index} className="boxRow spacer"/>
							];
						})}
					</div>

					{(documents == null) ? <div className="textCenter"><Spinner/></div> : ''}
				</div>

				<ReactModal
					isOpen={showProjectModalView}
					contentLabel=""
					onRequestClose={handleCloseProjectModalView}
					shouldCloseOnOverlayClick={true}
					className="rcModal withActions bigger"
					overlayClassName="rcOverlay"
				>
					<span onClick={handleCloseProjectModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

					<ProjectView handleClose={handleCloseProjectModalView} projectId={projectId} />
				</ReactModal>

				<ReactModal
					isOpen={showDocumentModalEdit}
					contentLabel=""
					onRequestClose={handleCloseDocumentModalView}
					shouldCloseOnOverlayClick={true}
					className="rcModal withActions bigger"
					overlayClassName="rcOverlay"
				>
					<span onClick={handleCloseDocumentModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

					<DocumentEdit handleRefresh={fetchDocuments} handleClose={handleCloseDocumentModalView} documentId={documentId}/>
				</ReactModal>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ClientView);
