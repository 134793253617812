import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {FieldArray, Form, Formik} from "formik"
import {useParams, useHistory} from "react-router-dom";
import BackButton from "../component/backButton";
import {isEmptyObject, objectClone} from "../utils";
import ManagePeople from "../form/managePeople";
import ClientPerson from "./clientPerson";
import ClientForm, {ClientFormNewContact, validateClient} from "./clientForm";
import {validateProfile} from "../profile/profileForm";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";

export default function ClientEdit(props) {
	let {id} = useParams();
	const history = useHistory();
	let managePeople = React.createRef();

	const [client, setClient] = useState({});

	useEffect(() => {
		async function fetchClient() {
			const response = await axios(API_URL + '/client/' + id);
			//console.log(response);

			if (response.data.data.client) {
				let foo = objectClone(response.data.data.client);
				if (foo.hasOwnProperty('province') && foo.province.hasOwnProperty('id')) {
					foo.province = foo.province.id;
				}
				if (foo.hasOwnProperty('fvProvince') && foo.fvProvince.hasOwnProperty('id')) {
					foo.fvProvince = foo.fvProvince.id;
				}
				if (foo.hasOwnProperty('persons')) {
					foo.persons.map((item) => {
						if (item.hasOwnProperty('position') && item.position.hasOwnProperty('id')) {
							item.position = item.position.id;
						}
					});
				}
				if (foo.hasOwnProperty('tags')) {
					foo.tags = foo.tags.map((tag) => {
						if (tag.hasOwnProperty('id')) {
							tag = tag.id;
						}
						return tag;
					});
				}
				if (response.data.data.client.hasOwnProperty('color') === false) {
					foo.color = '#FF0000';
				}

				setClient(foo);
			}
		}

		fetchClient();
	}, [id]);

	function handleSubmit(values, bag) {
		let toSave = {};
		Object.keys(values).map(function(item) {
			if (client[item] !== values[item]) {
				toSave[item] = values[item];
			}
		});

		if (toSave.hasOwnProperty('province')) {
			toSave['province'] = parseInt(toSave['province']);
		}
		if (toSave.hasOwnProperty('fvProvince')) {
			toSave['fvProvince'] = parseInt(toSave['fvProvince']);
		}

		let savePromisesArray = [];

		if (toSave.hasOwnProperty('persons')) {
			savePromisesArray.concat(saveExistingClientPersons(toSave));
		}
		if (toSave.hasOwnProperty('newPeople')) {
			savePromisesArray.concat(saveNewClientPersons(toSave));
		}

		Promise.all(savePromisesArray).then(() => {
			managePeople.current.save(id, () => {
				axios.patch(API_URL + '/client/' + id, toSave)
					.then(function(response) {
						bag.setSubmitting(false);
						//console.log(response.data);
						history.goBack();
					}).catch(function(error) {
						formikErrorHelper(error, bag);
					});
			});
		});
	}

	function saveExistingClientPersons(toSave) {
		let savePromisesArray = [];

		toSave.persons.map((item) => {
			savePromisesArray.push(
				axios.patch(API_URL + '/clientPerson/' + item.id, item)
			);
		});
		delete toSave.persons;

		return savePromisesArray;
	}

	function saveNewClientPersons(toSave) {
		let savePromisesArray = [];

		toSave.newPeople.map((item) => {
			item.client = id;
			savePromisesArray.push(
				axios.post(API_URL + '/clientPerson/create', item)
			);
		});
		delete toSave.newPeople;

		return savePromisesArray;
	}

	function validate(values) {
		let errors = validateClient(values);

		errors.persons = [];
		values.persons.map((item, i) => {
			let result = validateProfile(item);

			if (result !== null && isEmptyObject(result) === false) {
				errors.persons[i] = result;
			}
		});
		// remove persons error object if all add new person forms passes validation
		if (errors.persons.length === 0) {
			delete errors.persons;
		}

		return errors;
	}

	return (isEmptyObject(client) ? <div className="textCenter"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(client);
		delete initial.id;
		initial.newPeople = [];

		return (
			<Formik
				initialValues={initial}
				validate={validate}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

					return (
						<Form>
							<div className="headerWithButtons">
								<h1>Edytuj klienta</h1>
								<div className="right">
									<BackButton>Anuluj</BackButton>
									<button type="submit" disabled={isSubmitting} className="left32 withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</div>

							<div className="box">
								<ClientForm {...propsFormik}/>
							</div>

							<FieldArray
								name="persons"
								render={(arrayHelpers) => {
									return (
										<span>
											{values.persons.map((data, i) => (
												<ClientPerson
													positions={props.positions}
													setFieldValue={setFieldValue}
													arrayHelpers={arrayHelpers}
													values={values}
													data={data}
													key={i}
													index={i}
													name="persons"
												/>
											))}
										</span>
									);
								}}
							/>

							<ClientFormNewContact {...propsFormik}/>

							{status && <div className="errorMessage">{status}</div>}

							<ManagePeople ref={managePeople} value={client.clientService.map(function(item) { return item.id})}/>

							{/*<Dump value={values} />*/}
						</Form>
					)
				}}
			</Formik>
		);
	}
}
