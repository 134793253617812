import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {formatLocalizedDate, isEmptyObject} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";

export default function KnowledgeView(props) {

	const [knowledge, setKnowledge] = useState({});

	useEffect(() => {
		async function fetchKnowledge() {
			const response = await axios(API_URL + '/knowledgeBase/' + props.knowledgeId);
			//console.log(response.data.data);
			if (response.data.data.user) {
				setKnowledge(response.data.data.user);
			}
		}

		fetchKnowledge();
	}, [props.knowledgeId]);

	return (isEmptyObject(knowledge) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{knowledge.name}</h3>

					<div className="row with four columns">
						<div className="formItem column">
							<label htmlFor="name">Typ</label>
							<input type="text" readOnly value={knowledge.type ? knowledge.type.name : ''} className="fullWidth"/>

						</div>
						<div className="formItem column">
							<label htmlFor="name">Przypisanie do działu</label>
							{(knowledge.department) ? <span className="pill eggplant">{knowledge.department.name}</span> : ''}
						</div>

						<div className="formItem column">
							<label>Data ostatniej zmiany</label>
							<input type="text" readOnly value={formatLocalizedDate(parseISO(knowledge.modified))} className="fullWidth"/>
						</div>
						<div className="formItem column">
							<label>Przez kogo</label>
							<input type="text" readOnly value={knowledge.userModified ? knowledge.userModified.name : ''} className="fullWidth"/>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Opis</label>
						<textarea className="fullWidth" name="description" value={knowledge.description} rows="4" readOnly/>
					</div>

					<div className="row with four columns">
						{knowledge.files && knowledge.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					<div className="rcModalActions">
						<button type="submit" className="left32">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
