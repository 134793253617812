import axios from "axios";

export const FETCH_SERVICES_BEGIN = 'FETCH_SERVICES_BEGIN';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';

export function actionFetchServicesBegin() {
	return {
		type: FETCH_SERVICES_BEGIN
	};
}

export function actionFetchServicesSuccess(data) {
	return {
		type: FETCH_SERVICES_SUCCESS,
		list: data
	};
}

export function actionFetchServicesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_SERVICES_FAILURE,
		error: error
	};
}

export function actionFetchServices() {
	return dispatch => {
		dispatch(actionFetchServicesBegin());

		return axios.get(API_URL + '/definition/service/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list.list)) {
					dispatch(actionFetchServicesSuccess(response.data.data.list.list));
				} else {
					dispatch(actionFetchServicesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchServicesFailure(error));
			});
	};
}