import React, {useState} from 'react';
import axios from 'axios';
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../../form/field";
import {findIndexOfObjectWithPropertyValueInArray, objectClone} from "../../utils";
import {validateService} from "./adminServiceForm";
import SelectDepartment from "../../form/selectDepartment";
import SelectProcedure from "../../form/selectProcedure";
import {formikErrorHelper} from "../../form/formUtils";
import FileView from "../../component/fileView";
import FileUpload from "../../component/fileUpload";
import SelectProjectType from "../../form/selectProjectType";

export default function AdminServiceAdd(props) {

	const [uploadedFiles, setUploadedFiles] = useState([]);

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		}
	}

	function addFiles(uploadedFiles, serviceId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/service/file/' + serviceId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = objectClone(values);
		delete toSend.file;

		axios.post(API_URL + '/definition/service/create', toSend)
			.then(function(response) {
				let newId = response.data.id;
				let promises = [];
				promises.concat(addFiles(uploadedFiles, newId));

				Promise.all(promises).then(function() {
					bag.setSubmitting(false);

					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				});
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<Formik
			initialValues={{
				name: "",
				department: null,
				type: null,
				description: "",
				file: null,
			}}
			validate={validateService}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Tworzenie nowej Usługi</h3>

							<div className="row with two columns">
								<div className="formItem column">
									<label htmlFor="name">Nazwa usługi</label>
									<Field className="fullWidth" type="text" name="name"/>
									<ErrorMessage component="div" className="errorMessage" name="name"/>
								</div>
							</div>

							<div className="row with four columns">
								<div className="formItem column">
									<label htmlFor="department">Przypisz do działu</label>
									<SelectDepartment
										name="department"
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										value={values.department}
										className="fullWidth"
									/>
									<ErrorMessage component="div" className="errorMessage" name="department"/>
								</div>

								<div className="formItem column">
									<label htmlFor="type">Typ</label>
									<SelectProjectType
										name="type"
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										value={values.type}
										className="fullWidth"
									/>
									<ErrorMessage component="div" className="errorMessage" name="type"/>
								</div>
							</div>

							<div className="formItem">
								<label htmlFor="description">Opis usługi</label>
								<Field className="fullWidth" component="textarea" name="description" rows="4"/>
								<ErrorMessage component="div" className="errorMessage" name="description"/>
							</div>

							<div className="row with four columns">
								{uploadedFiles.map(function(file, index) {
									return (
										<div className="formItem column" key={file.id}>
											{(index === 0) ? <label>Załączone pliki</label> : <label>&nbsp;</label>}
											<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id)}/>
										</div>
									)
								})}
							</div>

							<FileUpload handleFile={handleFileAdd}/>

							<div className="formItem">
								<label htmlFor="procedures">Przypisz procedury</label>
								<SelectProcedure
									name="procedures"
									mode="multiple"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.procedures}
									className="fullWidth"
									showSearch={true}
								>
								</SelectProcedure>
								<ErrorMessage component="div" className="errorMessage" name="procedures"/>
							</div>

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump label="formik" value={propsFormik}/>*/}

							<div className="rcModalActions">
								<button type="submit" disabled={isSubmitting}>Dodaj</button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
