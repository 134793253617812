import React, {useEffect, useState} from 'react';

import axios from 'axios';
import {Link} from "react-router-dom";

import {ErrorMessage, Form, Formik} from "formik";
import Field from "./field";
import Dump from "../component/dump";

import Select from "../form/select";
import {Option} from "rc-select";
import CheckBox from './checkBox';
import RangeDateTimePicker from "./rangeDateTimePicker";
import moment from "moment";
import SelectPerson from "./selectPerson";
import SelectClient from "./selectClient";
import DatePicker from "./datePicker";
import SelectTags from "./selectTag";
import SelectRole from "./selectRole";
import DateTimePicker from "./dateTimePicker";


export default function FormTest(props) {

	const [types, setTypes] = useState(null);

	useEffect(() => {
		// By moving this function inside the effect, we can clearly see the values it uses.
		async function fetchTypes() {
			const response = await axios(API_URL + '/calendar/types/list');
			//console.log(response);
			setTypes(response.data.data.types);
		}

		fetchTypes();
	}, []);

	function handleSubmit(values, bag) {
		console.log(values);
		bag.setSubmitting(false);
	}

	return (
		<Formik
			initialValues={{
				type: 1,
				type2: 'Dodatkowy bardzo dlugi aby przetestowac',
				type3: [1, 2],
				type4: [
					"Spotkanie",
					"Urlop",
					"Sala konferencyjna"
				],
				department: "",
				lastName: 'Ziomkiewicz',
				date: moment("2019-11-14T22:59:00.000Z"),
				datetime: moment("2019-11-14T22:59:00.000Z"),
				range: [
					moment("2019-10-10T22:00:00.000Z"),
					moment("2019-11-14T22:59:00.000Z")
				],
				user: 2,
				users: [2, 8],
				client: 11,
				clients: [11,12],
				tags: [2],
				roles: ["ROLE_USER"],
				checkbox1: false,
				checkbox2: true,
			}}
			validate={values => {
				let errors = {};
				if (!values.type) {
					errors.type = 'Typ jest wymagany';
				}
				return errors;
			}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, initialValues} = propsFormik;

				return (
					<Form>
						<div className="headerWithButtons">
							<h1>Form test</h1>
							<div className="right">
								<Link to={'/'}>Powrót</Link>
								<button type="submit" value="Dodaj" className="left32 withChevron" disabled={isSubmitting}>
									Wyślij&nbsp;<i className="fas fa-chevron-right"/>
								</button>
							</div>
						</div>

						<div className="box">

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="tags">Tags</label>
										<SelectTags
											name="tags"
											style={{width: '100%'}}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.tags}
										/>
										<ErrorMessage component="div" className="errorMessage" name="tags"/>
									</div>
								</div>
							</div>

							<Dump value={values.tags}/>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="type2">Select single</label>
										<Select
											loading={types == null}
											name="type2"
											showSearch={true}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.type2}
											placeholder="Typ"
										>
											{types && types.map(function(item) {
												return (<Option value={item.id} key={'option' + item.id}>{item.name}</Option>);
											})}
											<Option value="Dodatkowy bardzo dlugi aby przetestowac">Dodatkowy bardzo dlugi aby przetestowac</Option>
											<Option value="Dodatkowy2">Dodatkowy2</Option>
											<Option value="Dodatkowy3">Dodatkowy3</Option>
											<Option value="Dodatkowy4">Dodatkowy4</Option>
											<Option value="Dodatkowy5">Dodatkowy5</Option>
										</Select>
										<ErrorMessage component="div" className="errorMessage" name="type2"/>
									</div>
								</div>

								<div className="column">
									<div className="formItem">
										<label htmlFor="typeDisabled">Select disabled</label>
										<Select
											loading={types == null}
											disabled={true}
											name="typeDisabled"
											value={values.type2}
											placeholder="Typ"
										>
											{types && types.map(function(item) {
												return (<Option value={item.id} key={'option' + item.id}>{item.name}</Option>);
											})}
										</Select>
										<ErrorMessage component="div" className="errorMessage" name="typeDisabled"/>
									</div>
								</div>
							</div>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="type4">Select multiple</label>
										<Select
											name="type4"
											mode="multiple"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.type4}
											optionLabelProp="label"
										>
											<Option value="Spotkanie">Spotkanie</Option>
											<Option value="Urlop">Urlop</Option>
											<Option value="Sala konferencyjna">Sala konferencyjna</Option>
										</Select>
										<ErrorMessage component="div" className="errorMessage" name="type4"/>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="type4Disabled">Select multiple disabled</label>
										<Select
											disabled
											name="type4Disabled"
											mode="multiple"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.type4}
											optionLabelProp="label"
										>
											<Option value="Spotkanie">Spotkanie</Option>
											<Option value="Urlop">Urlop</Option>
											<Option value="Sala konferencyjna">Sala konferencyjna</Option>
										</Select>
										<ErrorMessage component="div" className="errorMessage" name="type4Disabled"/>
									</div>
								</div>
							</div>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="user">Select single user with avatar</label>
										<SelectPerson
											name="user"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.user}
										/>
										<ErrorMessage component="div" className="errorMessage" name="user"/>
									</div>
								</div>

								<div className="column">
									<div className="formItem">
										<label htmlFor="users">Select multiple users</label>
										<SelectPerson
											name="users"
											mode="multiple"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.users}
										/>
										<ErrorMessage component="div" className="errorMessage" name="users"/>
									</div>
								</div>
							</div>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="client">Select single client</label>
										<SelectClient
											name="client"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.client}
										/>
										<ErrorMessage component="div" className="errorMessage" name="client"/>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="clients">Select multiple client</label>
										<SelectClient
											name="clients"
											mode="multiple"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.clients}
										/>
										<ErrorMessage component="div" className="errorMessage" name="clients"/>
									</div>
								</div>
							</div>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="department">Native select</label>
										<Field component="select" name="department">
											<option value="spotkanie">Spotkanie</option>
											<option value="urlop">Urlop</option>
											<option value="sala">Sala konferencyjna</option>
										</Field>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="departmentDisabled">Native select disabled</label>
										<Field component="select" name="departmentDisabled" disabled>
											<option value="spotkanie">Spotkanie</option>
											<option value="urlop">Urlop</option>
											<option value="sala">Sala konferencyjna</option>
										</Field>
									</div>
								</div>
							</div>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="lastName">Text input</label>
										<Field type="text" name="lastName"/>
										<ErrorMessage component="div" className="errorMessage" name="lastName"/>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="lastNameDisabled">Text input disabled</label>
										<Field type="text" name="lastNameDisabled" disabled/>
										<ErrorMessage component="div" className="errorMessage" name="lastNameDisabled"/>
									</div>
								</div>
							</div>

							<div className="formItem">
								<CheckBox
									name="checkbox1"
									defaultChecked={false}
									checked={values.checkbox1}
									onChange={setFieldValue}
								>
									Checkbox
								</CheckBox>
								<CheckBox
									name="checkbox2"
									defaultChecked={true}
									checked={values.checkbox2}
									onChange={setFieldValue}
								>
									Checkbox
								</CheckBox>
								<CheckBox
									name="checkbox1"
									disabled
									defaultChecked={false}
									checked={values.checkbox1}
									onChange={setFieldValue}
								>
									Checkbox disabled
								</CheckBox>
							</div>
							<div className="formItem">
								<RangeDateTimePicker
									name="range"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.range}
								/>
								<ErrorMessage component="div" className="errorMessage" name="range"/>
							</div>
							<div className="formItem">
								<DatePicker
									name="date"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.date}
								/>
								<ErrorMessage component="div" className="errorMessage" name="date"/>
							</div>
							<div className="formItem">
								<DateTimePicker
									name="datetime"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.datetime}
								/>
								<ErrorMessage component="div" className="errorMessage" name="datetime"/>
							</div>

							<div className="formItem">
								<label htmlFor="roles">Roles</label>
								<SelectRole
									name="roles"
									style={{width: '100%'}}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.roles}
								/>
							</div>
							<Dump value={values.roles}/>

							{status && <div className="errorMessage">{status}</div>}

							{<Dump label="formik" value={values}/>}
							{<Dump label="formik" value={initialValues}/>}
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
