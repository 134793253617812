import Upload from 'rc-upload';
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getFilenameFromPath, getFileUrl} from "../utils";

export default function AvatarUpload(props) {
	const accessToken = useSelector(state => state.auth.accessToken);

	const [loading, setLoading] = useState(false);

	let fileUrl = '';
	if (props.picture && props.picture.hasOwnProperty('id') && props.picture.hasOwnProperty('path')) {
		let filename = getFilenameFromPath(props.picture.path);
		fileUrl = getFileUrl(props.picture.id, filename);
	}

	const [imageUrl, setImageUrl] = useState(fileUrl);

	let showChange = !!imageUrl;
	let isEmpty = !imageUrl;

	function onSuccess(result, file) {
		//console.log('onSuccess', result, file);

		setLoading(false);

		if (typeof props.onSuccess == 'function') {
			props.onSuccess(result.data.file);
		}
	}

	function beforeUpload(file) {
		//console.log('beforeUpload', file);

		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	}

	function onStart(file) {
		//console.log('onStart', file, file.name);

		setLoading(true);

		return new Promise(function(resolve) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function() {
				setImageUrl(reader.result);
			}
		});
	}

	function onError(err, response, file) {
		//console.log('onError', err);

		setLoading(false);
	}

	function onProgress({ percent }, file) {
		//console.log('onProgress', `${percent}%`, file.name);
	}

	const uploadButton = <div className={"plus" + (loading ? 'loading' : '')}>+</div>;

	return (
		<span>
			<Upload
				name="file"
				listType="picture-card"
				className="avatar-uploader"
				style={{outline: 0}}
				showUploadList={false}
				action={API_URL + "/media/upload"}
				//beforeUpload={beforeUpload}
				onSuccess={onSuccess}
				onError={onError}
				onProgress={onProgress}
				onStart={onStart}
				headers={{'access-token': accessToken}}
			>
				<div className={"avatar large editable" + (isEmpty ? ' empty' : '')}>
					{isEmpty ? uploadButton : <img src={imageUrl} alt=""/> }
					{(showChange ? <div className="editableMessage">Zmień</div>: '')}
				</div>
			</Upload>
			{/*<Dump label="props" value={props}/>*/}
			{/*<Dump label="showChange" value={showChange}/>*/}
			{/*<Dump label="isEmpty" value={isEmpty}/>*/}
			{/*<Dump label="loading" value={loading}/>*/}
		</span>
	);
}
