import React, {useEffect, useState} from 'react'
import axios from "axios";
import {getNameFromId, isEmptyObject, objectClone} from "../utils";

import Sortable from "../component/sortable";
import ReactModal from "react-modal";
import Spinner from "../component/spinner";
import Pagination from 'rc-pagination';
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';

import TaskRow from "./taskRow";
import TaskSettings from "./taskSettings";
import TaskView from "./taskView";
import TaskFilters from "./filterTask";

import {useDispatch, useSelector} from "react-redux";
import {actionFetchClients} from "../state/actionsClients";
import {actionFetchPeople} from "../state/actionsPeople";
import {actionFetchProfile} from "../state/actionsProfile";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import {useHistory, useParams} from "react-router-dom";

export default function TaskList(props) {
	const dispatch = useDispatch();
	const urlParams = useParams();
	const history = useHistory();

	const me = useSelector(state => state.profile.me);
	const meLoading = useSelector(state => state.profile.loading);

	const clients = useSelector(state => state.clients.list);
	const clientsLoading = useSelector(state => state.clients.loading);

	const people = useSelector(state => state.people.list);
	const peopleLoading = useSelector(state => state.people.loading);

	const taskStatuses = useSelector(state => state.taskStatuses.list);
	const taskStatusesLoading = useSelector(state => state.taskStatuses.loading);

	useEffect(() => {
		if (clientsLoading === false && (clients == null || clients.length === 0)) {
			dispatch(actionFetchClients());
		}

		if (peopleLoading === false && (people == null || people.length === 0)) {
			dispatch(actionFetchPeople());
		}

		if (taskStatusesLoading === false && (taskStatuses == null || taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}

		if (meLoading === false && isEmptyObject(me)) {
			dispatch(actionFetchProfile());
		}
	}, []);

	useEffect(() => {
		if (urlParams.taskId && urlParams.projectId && urlParams.serviceId && urlParams.procedureId) {
			handleOpenModalView(urlParams.taskId, urlParams.projectId, urlParams.serviceId, urlParams.procedureId)
		} else {
			setShowModalView(false);
		}
	}, [urlParams]);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [tasksCount, setTasksCount] = useState(1);

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [tasks, setTasks] = useState(null);

	const [taskId, setTaskId] = useState(null);
	const [projectId, setProjectId] = useState(null);
	const [serviceId, setServiceId] = useState(null);
	const [procedureId, setProcedureId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('taskCreated');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalFilters, setShowModalFilters] = useState(false);
	const [showModalSettings, setShowModalSettings] = useState(false);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchTasks(source) {
		let params = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			params.search = search;
		}

		if (filters.hasOwnProperty('status') && filters.status.length > 0) {
			params['filter[status]'] = filters.status.map((item) => item.id);
		}

		if (orderColumn && orderDirection !== null) {
			params.sort = orderColumn;
			if (orderDirection === true) {
				params.order = 'desc';
			}
			if (orderDirection === false) {
				params.order = 'asc';
			}
		}

		if (me.id) {
			const response = await axios.get(API_URL + '/project/task/assigned/' + me.id, {params: params, cancelToken: source.token});

			let data = response.data.data;
			//console.log(data);
			if (Array.isArray(data.list)) {
				data.list.map(function(item) {
					if (item.hasOwnProperty('procedure') && item.procedure.hasOwnProperty('id')) {
						item.procedure = item.procedure.id;
					}

					if (item.hasOwnProperty('service') && item.service.hasOwnProperty('id')) {
						item.service = item.service.id;
					}
				});

				setTasks(data.list);
				setTasksCount(data.count);
			}
		}
	}

	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchTasks(source);

		return () => {
			source.cancel('Request canceled');
		}
	}, [search, currentPage, perPage, orderDirection, orderColumn, filters, me]);

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleRefresh() {
		fetchTasks();
	}

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function handleCloseModalSettings() {
		setShowModalSettings(false);
	}

	function handleCloseModalView() {
		history.push("/task");
	}

	function handleOpenModalView(taskId, projectId, serviceId, procedureId) {
		setTaskId(taskId);
		setProjectId(projectId);
		setServiceId(serviceId);
		setProcedureId(procedureId);
		setShowModalView(true);
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Twoje zadania</h1>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>

				<div className="right">
					{/*<button className="transparent">Pokaż wszystkie</button>*/}
					{/*<button className="transparent left12 padLeft10" onClick={() => setShowModalSettings(true) }><span className="icon cog"/> Ustawienia</button>*/}
				</div>
			</div>

			<div className="filters">
				{filters.type && filters.type.map((item) => {
					return (
						<span key={"filterType" + item.id} className="filter">
							<i className="fas fa-times" onClick={() => {
								let newFilters = objectClone(filters);
								if (newFilters.type.indexOf(item) > -1) {
									newFilters.type.splice(newFilters.type.indexOf(item), 1);
									setFilters(newFilters);
								}
							}}/>
							{item.name}
						</span>
					);
				})}

				{filters.status && filters.status.map((item) => {
					return (
						<span key={"filterStatus" + item.id} className="filter">
							<i className="fas fa-times" onClick={() => {
								let newFilters = objectClone(filters);
								if (newFilters.status.indexOf(item) > -1) {
									newFilters.status.splice(newFilters.status.indexOf(item), 1);
									setFilters(newFilters);
								}
							}}/>
							{item.name}
						</span>
					);
				})}

				{filters.client ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.client;
							setFilters(newFilters);
						}}/>
						{getNameFromId(clients, filters.client)}
					</span>
				) : ''}

				{filters.user ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.user;
							setFilters(newFilters);
						}}/>
						{getNameFromId(people, filters.user)}
					</span>
				) : ''}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell">Lp.</div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa zadania</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="project" value={(orderColumn === 'project') ? orderDirection : null}>Projekt</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="estimation" value={(orderColumn === 'estimation') ? orderDirection : null}>Prognozowany<br/> czas realizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealization" value={(orderColumn === 'dateRealization') ? orderDirection : null}>Prognozowana<br/> data wykonania</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealizationLimit" value={(orderColumn === 'dateRealizationLimit') ? orderDirection : null}>Data i godzina graniczna<br/>do wykonania</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="orderedBy" value={(orderColumn === 'orderedBy') ? orderDirection : null}>Zlecający</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="taskCreated" value={(orderColumn === 'taskCreated') ? orderDirection : null}>Utworzono</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="status" value={(orderColumn === 'status') ? orderDirection : null}>Status</Sortable></div>
					<div className="boxCell"/>
				</div>

				{tasks && tasks.map(function(item, index) {
					return [
						<TaskRow key={'task' + index} value={item} lp={index + 1} refresh={handleRefresh} handleOpenModalView={handleOpenModalView} taskStatuses={taskStatuses}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(tasks == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={tasksCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={filters}/>*/}

			{/*<div className="row with three columns">*/}
			{/*	<div className="column">*/}
			{/*		<b><span className="icon hamburger right8"/> Ostatnie zmiany statusów</b>*/}
			{/*		<div className="box top12">*/}
			{/*			/!*<div className="boxEntry">*/}
			{/*				<small className="light">30.10.2019 11:23</small><br/>*/}
			{/*				<div className="pill dot eggplant right8 top6 bottom6">M</div>*/}
			{/*				<div className="pill eggplant right12 top6 bottom6">Omówenie montaży</div><br/>*/}
			{/*				<b>w realizacji</b> <i>zmieniono na</i> <b>zrealizowano</b>*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<small className="light">30.10.2019 11:23</small><br/>*/}
			{/*				<div className="pill dot eggplant right8 top6 bottom6">M</div>*/}
			{/*				<div className="pill eggplant right12 top6 bottom6">Omówenie montaży</div><br/>*/}
			{/*				<b>w realizacji</b> <i>zmieniono na</i> <b>zrealizowano</b>*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<small className="light">30.10.2019 11:23</small><br/>*/}
			{/*				<div className="pill dot blue right8 top6 bottom6">M</div>*/}
			{/*				<div className="pill blue    right12 top6 bottom6">Omówenie montaży</div><br/>*/}
			{/*				<b>w realizacji</b> <i>zmieniono na</i> <b>zrealizowano</b>*/}
			{/*			</div>*!/*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className="column">*/}
			{/*		<b><span className="icon calendar right8"/> Kalendarz</b>*/}
			{/*		<div className="box top12">*/}
			{/*			/!*<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*!/*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className="column">*/}
			{/*		<b><span className="icon info right8"/> Powiadomienia</b>*/}
			{/*		<div className="box top12">*/}
			{/*			/!*<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*/}
			{/*			<div className="boxEntry">*/}
			{/*				<div className="pill blue right12">01.11.2019</div> Wyliczenie oszczędności*/}
			{/*			</div>*!/*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<Dump value={documents}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filters}/>*/}

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<TaskFilters filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalSettings}
				contentLabel=""
				onRequestClose={handleCloseModalSettings}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalSettings} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<TaskSettings filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalSettings}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<TaskView
					handleRefresh={handleRefresh}
					handleClose={handleCloseModalView}
					taskId={taskId}
					projectId={projectId}
					serviceId={serviceId}
					procedureId={procedureId}
				/>
			</ReactModal>
		</div>
	);
}
