import React, {useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, objectClone} from "../utils";
import IssueForm, {validateIssue} from "./issueForm";
import {formikErrorHelper} from "../form/formUtils";

export default function IssueAdd(props) {

	const [uploadedFiles, setUploadedFiles] = useState([]);

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		}
	}

	function addFiles(uploadedFiles, knowledgeId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/issue/file/' + knowledgeId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = objectClone(values);
		delete toSend.file;

		axios.post(API_URL + '/issue/create', toSend)
			.then(function(response) {
				let newId = response.data.id;
				let promises = [];

				promises.concat(addFiles(uploadedFiles, newId));

				Promise.all(promises).then(function() {
					bag.setSubmitting(false);

					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				});
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<Formik
			initialValues={{
				name: "",
				users: [],
				client: null,
				description: "",
				file: null,
			}}
			validate={validateIssue}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Nowe zgłoszenie</h3>

							<IssueForm
								{...propsFormik}
								handleFileAdd={handleFileAdd}
								handleFileDelete={handleFileDelete}
								uploadedFiles={uploadedFiles}
							/>

							<div className="rcModalActions">
								<button type="submit" className="left32" disabled={isSubmitting}>Dodaj</button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
