import React from 'react';
import {Form, Formik} from "formik";
import SelectPerson from "../form/selectPerson";

export default function FilterClient(props) {

	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>

							<div className="formItem">
								<label htmlFor="persons" className="bottom10">Opiekun</label>
								<SelectPerson
									name="persons"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.persons}
									className="fullWidth"
									mode="multiple"
								/>
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
