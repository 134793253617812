import React, {useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import {useHistory} from "react-router-dom"
import ProjectForm, {ProjectTaskForm, validateProject} from "./projectForm";
import moment from "moment";
import BackButton from "../component/backButton";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
import {getCompanyFromService, getProjectTypeFromService} from "./projectUtils";
import Dump from "../component/dump";

export default function ProjectAdd(props) {
	const history = useHistory();

	const [service, setService] = useState(null);
	const [tasksToSave, setTasksToSave] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	async function fetchService(id = null) {
		if (id !== null) {
			setService({});

			const response = await axios(API_URL + '/definition/service/' + id);
			//console.log(response.data.data.service);
			if (response.data.data.service) {
				setService(response.data.data.service);
			}
		}
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		}
	}

	function addFiles(uploadedFiles, projectId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/project/file/' + projectId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = objectClone(values);
		let serviceId = toSend.services;

		if (toSend.services) {
			toSend.services = [toSend.services];
		}

		// TODO: these two fields should not be handled by frontend:
		let type = getProjectTypeFromService(service);
		toSend.type = (type && type.hasOwnProperty('id')) ? type.id : null;

		let company = getCompanyFromService(service);
		toSend.company = (company && company.hasOwnProperty('id')) ? company.id : null;

		function finish() {
			bag.setSubmitting(false);
			history.goBack();
		}

		axios.post(API_URL + '/project/create', toSend)
			.then(function(response) {
				let newId = response.data.id;

				let tasksPromises = saveTasks(newId, serviceId);

				let promises = [];
				promises.concat(addFiles(uploadedFiles, newId));
				promises.concat(tasksPromises);

				Promise.all(promises).then(function() {
					if (tasksPromises.length > 0) {
						Promise.all(saveTasksChecks(newId, serviceId)).then(function() {
							finish();
						});
					} else {
						finish();
					}
				});
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	function saveTasks(projectId, serviceId) {
		let promisesArray = [];

		for (let procedureId in tasksToSave) {
			for (let taskId in tasksToSave[procedureId]) {
				//console.log(taskId, tasksToSave[taskId]);
				let toSave = tasksToSave[procedureId][taskId];
				toSave.project = projectId;
				toSave.service = serviceId;
				toSave.procedure = parseInt(procedureId);
				toSave.task = parseInt(taskId);

				//console.log(toSave);

				promisesArray.push(
					axios.post(API_URL + '/project/task/assign', toSave)
				);
			}
		}

		return promisesArray;
	}

	function saveTasksChecks(projectId, serviceId) {
		let promisesArray = [];

		for (let procedureId in tasksToSave) {
			promisesArray.push(
				axios.get(API_URL + '/project/task/checkAssigned/' + projectId + '/' + serviceId + '/' + procedureId)
			);
		}

		return promisesArray;
	}

	return (
		<Formik
			initialValues={{
				name: "",
				client: "",
				services: "",
				description: "",
				implementationDate: moment(),
				unitQuantity: 1,
				unitValue: 0,
				recurrent: 0,
			}}
			validate={validateProject}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<>
						<Form>
							<div className="headerWithButtons">
								<h1>Tworzenie nowego Projektu</h1>
								<div className="right">
									<BackButton>Anuluj</BackButton>
									<button type="submit" disabled={isSubmitting} className="left32 withChevron">
										Dodaj&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</div>

							<ProjectForm
								{...propsFormik}
								onServiceChange={(name, value) => {
									fetchService(value);
									setFieldValue(name, value);
								}}
								handleFileAdd={handleFileAdd}
								handleFileDelete={handleFileDelete}
								uploadedFiles={uploadedFiles}
								service={service}
							/>
						</Form>

						<h3 className="bottom24">Przydziel zadania</h3>
						{(isEmptyObject(service) ? <div className="textCenter top96"><Spinner/></div> : renderTasks()) }

						{/*<Dump value={tasksToSave}/>*/}
					</>
				)
			}}
		</Formik>
	);

	function renderTasks() {
		return (
			<>
				{service && Array.isArray(service.procedures) && service.procedures.map(function(procedure) {
					return (
						<span key={procedure.id}>
							<h4 className="bottom12">{procedure.name}</h4>

							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell">Lp.</div>
									<div className="boxCell">Nazwa zadania</div>
									<div className="boxCell">Przydzielona osoba</div>
									<div className="boxCell">Graniczna data wykonania</div>
								</div>

								{procedure.tasks.map(function(task, index) {
									return (
										<ProjectTaskForm
											key={'projectTask' + task.id}
											lp={index + 1}
											procedureId={procedure.id}
											task={task}
											getFieldValue={getFieldValue}
											updateTask={updateTask}
										/>
									);
								})}
							</div>
						</span>
					);
				})}
			</>
		);
	}

	function getFieldValue(taskId, procedureId, name) {
		let value = null;

		if (tasksToSave.hasOwnProperty(procedureId)) {
			if (tasksToSave[procedureId].hasOwnProperty(taskId)) {
				if (tasksToSave[procedureId][taskId].hasOwnProperty(name)) {
					value = tasksToSave[procedureId][taskId][name];
				}
			}
		}

		return value;
	}

	function updateTask(taskId, procedureId, prop, value) {
		let foo = objectClone(tasksToSave);

		if (foo.hasOwnProperty(procedureId) === false) {
			foo[procedureId] = {};
		}

		if (foo[procedureId].hasOwnProperty(taskId) === false) {
			foo[procedureId][taskId] = {};
		}

		foo[procedureId][taskId][prop] = value;

		setTasksToSave(foo);
	}
}
