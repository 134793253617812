import React, {useEffect, useState} from 'react';
import IssueRow from "./issueRow";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import axios from "axios";
import IssueAdd from "./issueAdd";
import IssueEdit from "./issueEdit";
import IssueView from "./issueView";
import {useDispatch, useSelector} from "react-redux";
import {actionFetchPeople} from "../state/actionsPeople";
import FilterIssue from "./filterIssue";
import {formatLocalizedDate, getNameFromId, objectClone} from "../utils";
import classNames from "classnames";

export default function IssueList(props) {
	const dispatch = useDispatch();

	const people = useSelector(state => state.people.list);
	const peopleLoading = useSelector(state => state.people.loading);

	useEffect(() => {
		if (peopleLoading === false && (people == null || people.length === 0)) {
			dispatch(actionFetchPeople());
		}
	}, []);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [count, setCount] = useState(1);

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [issue, setIssue] = useState(null);
	const [issueId, setIssueId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('created');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalView, setShowModalView] = useState(false);
	const [showModalFilters, setShowModalFilters] = useState(false);

	const [showArchive, setShowArchive] = useState(false);

	async function fetchIssues() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (filters.hasOwnProperty('user')) {
			toSend['filter[user]'] = filters.user;
		}
		if (filters.hasOwnProperty('orderedBy')) {
			toSend['filter[orderedBy]'] = filters.orderedBy;
		}
		if (filters.hasOwnProperty('dateRealization')) {
			toSend['filter[dateRealization]'] = filters.dateRealization.format('YYYY-MM-DD');
		}

		if (showArchive) {
			toSend['filter[status]'] = [10,30];
		} else {
			toSend['filter[status]'] = [1,2,9];
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/issue/list', {params: toSend});

		let data = response.data.data;
		//console.log(data);
		if (Array.isArray(data.list)) {
			setIssue(data.list);
			setCount(data.count);
		}
	}

	useEffect(() => {
		fetchIssues();
	}, [search, currentPage, perPage, orderDirection, orderColumn, filters, showArchive]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setIssueId(id);
		setShowModalView(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setIssueId(id);
		setShowModalEdit(true);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	function handleRefresh() {
		fetchIssues();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>{showArchive ? 'Historia zgłoszeń' : 'Zgłoszenia'}</h1>
					<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</button>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>
				<div className="right">
					<button className={classNames('right8', 'pill', (showArchive ? 'green' : 'grey'))} onClick={() => setShowArchive(!showArchive)}>
						{showArchive ? 'Wszystkie' : 'Historia zgłoszeń'}
					</button>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="filters">
				{filters.orderedBy ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.orderedBy;
							setFilters(newFilters);
						}}/>
						Zlecający {getNameFromId(people, filters.orderedBy)}
					</span>
				) : ''}

				{filters.user ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.user;
							setFilters(newFilters);
						}}/>
						Wykonawca {getNameFromId(people, filters.user)}
					</span>
				) : ''}

				{filters.dateRealization ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.dateRealization;
							setFilters(newFilters);
						}}/>
						Data realizacji {formatLocalizedDate(filters.dateRealization.toDate())}
					</span>
				) : ''}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealization" value={(orderColumn === 'dateRealization') ? orderDirection : null}>Graniczna data zakończenia</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="orderedBy" value={(orderColumn === 'orderedBy') ? orderDirection : null}>Zlecający</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="user" value={(orderColumn === 'user') ? orderDirection : null}>Przydzielono do</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="created" value={(orderColumn === 'created') ? orderDirection : null}>Utworzono</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="status" value={(orderColumn === 'status') ? orderDirection : null}>Status</Sortable></div>
					<div className="boxCell"/>
				</div>

				{issue && issue.map(function(item, index) {
					return [
						<IssueRow
							key={'issue' + index}
							value={item}
							handleOpenModalEdit={handleOpenModalEdit}
							handleOpenModalView={handleOpenModalView}
							refresh={handleRefresh}
						/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(issue === null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={count}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={count}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filterKnowledgeType}/>*/}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<IssueAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<IssueEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} issueId={issueId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<IssueView handleClose={handleCloseModalView} issueId={issueId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<FilterIssue filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>
		</div>
	);
}
