import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {formatLocalizedDate, isEmptyObject} from "../../utils";
import Spinner from "../../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../../component/fileView";

export default function AdminTaskView(props) {

	const [task, setTask] = useState({});

	useEffect(() => {
		async function fetchTask() {
			const response = await axios(API_URL + '/definition/task/' + props.taskId);
			//console.log(response.data.data);
			if (response.data.data.task) {
				setTask(response.data.data.task);
			}
		}

		fetchTask();
	}, [props.taskId]);

	function getProcedureList(procedures = {}) {
		let toReturn = "";
		for (let key in procedures) {
			toReturn = toReturn + procedures[key].name + ', ';
		}
		return toReturn.slice(0, -2);
	}

	return (isEmptyObject(task) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{task.name}</h3>

					<div className="row with four columns">
						<div className="formItem column">
							<label htmlFor="name">Typ zadania</label>
							<input type="text" readOnly value={task.type ? task.type.name : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="name">Przypisanie do działu</label>
							{(task.department) ? <span className="pill eggplant">{task.department.name}</span> : ''}
						</div>

						<div className="formItem column">
							<label>Data ostatniej zmiany</label>
							<input type="text" readOnly value={formatLocalizedDate(parseISO(task.modified))} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Przez kogo</label>
							<input type="text" readOnly value={task.userModified ? task.userModified.name : ''} className="fullWidth"/>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Opis zadania</label>
						<textarea className="fullWidth" name="description" value={task.description} rows="4" readOnly/>
					</div>

					<div className="row with four columns">
						{task.files && task.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					<div className="formItem">
						<label htmlFor="description">Używane w procedurach</label>
						<textarea className="fullWidth" name="used" rows="4" readOnly value={getProcedureList(task.procedures)}/>
					</div>

					<div className="rcModalActions">
						<button type="submit">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
