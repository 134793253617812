import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from "moment";
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../form/field";
import {formikErrorHelper} from "../form/formUtils";
import {findIndexOfObjectWithPropertyValueInArray, formatLocalizedDate, formatLocalizedDateTime, isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import DatePicker from "../form/datePicker";
import SelectTaskStatus from "../form/selectTaskStatus";
import CommentAdd from "../component/commentAdd";
import CommentView from "../component/commentView";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";
import {parseISO} from "date-fns";
import PillStatus from "../component/pillStatus";

export default function TaskView(props) {

	const [projectTask, setProjectTask] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	async function fetchProjectTask() {
		const response = await axios.get(API_URL + '/project/task/' + props.projectId + '/' + props.taskId + '/' + props.procedureId + '/' + props.serviceId);

		if (response.data.data.project) {
			let foo = objectClone(response.data.data.project);

			if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('name')) {
				foo.client = foo.client.name;
			}

			if (foo.hasOwnProperty('orderedBy') && foo.orderedBy.hasOwnProperty('name')) {
				foo.orderedBy = foo.orderedBy.name;
			}

			if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('name')) {
				foo.user = foo.user.name;
			}

			if (foo.hasOwnProperty('project') && foo.project.hasOwnProperty('name')) {
				foo.project = foo.project.name;
			}

			if (foo.hasOwnProperty('status') && foo.status.hasOwnProperty('id')) {
				foo.statusFull = foo.status;
				foo.status = foo.status.id;
			}

			if (foo.hasOwnProperty('estimation')) {
				foo.estimation = parseInt(foo.estimation);
			}

			if (Array.isArray(foo.services) && foo.services.length > 0) {
				foo.service = foo.services.pop();
				delete foo.services;
			}

			setProjectTask(foo);
		}
	}

	useEffect(() => {
		fetchProjectTask();
	}, [props.taskId, props.projectId, props.procedureId, props.serviceId]);

	function getFinishedDate() {
		let array = [...projectTask.statusHistory];

		if (array && Array.isArray(array) && array.length > 0) {
			let entry = array[0];
			if (entry && entry.hasOwnProperty('status') && (entry.status == 9 || entry.status === 10)) {
				return entry.created;
			}
		}

		return null;
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/project/task/file/' + props.projectId + '/' + props.taskId + '/' + fileId + '/' + props.procedureId + '/' + props.serviceId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, projectId, taskId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/project/task/file/' + projectId + '/' + taskId + '/' + item.id + '/' + props.procedureId + '/' + props.serviceId)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (projectTask[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		if (toSend.hasOwnProperty('status')) {
			toSend['status'] = parseInt(toSend['status']);
		}

		delete toSend.files;
		delete toSend.statusHistory;
		delete toSend.statusFull;

		let promises = [];
		promises.concat(addFiles(uploadedFiles, props.projectId, props.taskId));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/project/task/' + props.projectId + '/' + props.taskId + '/' + props.procedureId + '/' + props.serviceId, toSend)
				.then(function() {
					axios.get(API_URL + '/project/task/checkAssigned/' + props.projectId + '/' + props.serviceId + '/' + props.procedureId)
						.then(function() {
							bag.setSubmitting(false);

							if (typeof props.handleRefresh === 'function') {
								props.handleRefresh();
							}
							if (typeof props.handleClose === 'function') {
								props.handleClose();
							}
						});
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});
	}

	function handleCommentAdded(newCommentId) {
		axios.post(API_URL + '/project/task/comment/' + props.projectId + '/' + props.taskId + '/' + newCommentId + '/' + props.procedureId + '/' + props.serviceId)
			.then(function(response) {
				fetchProjectTask();
			});
	}

	function isChangingStatusPossible() {
		if (projectTask.hasOwnProperty('previousTaskStatus') === false) {
			return true;
		}

		// if canceled or finished
		return (projectTask.previousTaskStatus === 9 || projectTask.previousTaskStatus === 10);
	}

	return (isEmptyObject(projectTask) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		let initial = objectClone(projectTask);
		delete initial.id;
		delete initial.task;
		delete initial.client;
		delete initial.description;
		delete initial.orderedBy;
		delete initial.user;
		delete initial.comments;
		delete initial.project;
		initial.dateRealization = initial.dateRealization ? moment(initial.dateRealization) : null;

		return (
			<Formik
				initialValues={initial}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<div className="rcModelContents">
							<Form>
								<h3 className="top0 bottom24">{projectTask.task ? projectTask.task.name : ''}</h3>

								<div className="row with four columns">
									<div className="formItem column">
										<label htmlFor="name">Nazwa projektu</label>
										<input className="fullWidth" type="text" name="name" readOnly value={projectTask.project}/>
									</div>

									<div className="formItem column">
										<label htmlFor="client">Klient</label>
										<input className="fullWidth" type="text" name="client" readOnly value={projectTask.client}/>
									</div>

									<div className="formItem column">
										<label htmlFor="orderedBy">Osoba zlecająca</label>
										<input className="fullWidth" type="text" name="orderedBy" readOnly value={projectTask.orderedBy}/>
									</div>

									<div className="formItem column">
										<label htmlFor="status">Status</label>
										{!isChangingStatusPossible() ? <PillStatus value={values.statusFull}/> :
											<SelectTaskStatus
												className="fullWidth"
												name="status"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												value={values.status}
												disabled={!isChangingStatusPossible()}
											/>
										}
										<ErrorMessage component="div" className="errorMessage" name="status"/>
									</div>
								</div>

								<div className="row with four columns">
									<div className="formItem column">
										<label htmlFor="dateRealization">Prognozowana data zakończenia</label>
										<DatePicker
											className="fullWidth"
											name="dateRealization"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateRealization}
										/>
									</div>

									<div className="formItem column">
										<label htmlFor="estimation">Prognozowany czas realizacji</label>
										<Field
											className="noMinimumWidth"
											name="estimation"
											type="number"
											style={{width: 100}}
											value={values.estimation}
										/>
										&nbsp;&nbsp;godz.
									</div>

									<div className="formItem column">
										<label htmlFor="dateRealizationLimit">Graniczna data zakończenia</label>
										<input className="fullWidth" type="text" readOnly value={formatLocalizedDate(parseISO(projectTask.dateRealizationLimit))}/>
									</div>

									{!getFinishedDate() ? '' :
										<div className="formItem column">
											<label htmlFor="dateRealizationLimit">Faktyczna data zakończenia</label>
											<input className="fullWidth" type="text" readOnly value={formatLocalizedDateTime(parseISO(getFinishedDate()))}/>
										</div>
									}
								</div>

								<h4>Komentarze</h4>
								{projectTask.comments && projectTask.comments.map(function(item) {
									return <CommentView
										commentId={item.id}
										text={item.text}
										date={item.created}
										poster={item.createdBy ? item.createdBy.name : ''}
										posterId={item.createdBy ? item.createdBy.id : ''}
										refresh={fetchProjectTask}
									    key={"comment" + item.id}
									/>;
								})}
								<CommentAdd handleAdd={handleCommentAdded}/>

								<div className="row with four columns">
									{projectTask.files && projectTask.files.map(function(file, index) {
										return (
											<div className="formItem column" key={file.id}>
												{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
												<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, projectTask.files, setFieldValue)}/>
											</div>
										);
									})}
									{uploadedFiles.map(function(file) {
										return (
											<div className="formItem column" key={file.id}>
												<label>&nbsp;</label>
												<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, projectTask.files, setFieldValue)}/>
											</div>
										)
									})}
								</div>

								<FileUpload handleFile={handleFileAdd}/>

								<h3 className="bottom24">Realizowane w ramach projektu: {projectTask.project}</h3>
								{renderTasks()}

								{status && <div className="errorMessage">{status}</div>}

								<div className="rcModalActions">
									<button type="submit" disabled={isSubmitting} className="withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
		);
	}

	function renderTasks() {
		return (
			<>
				{projectTask.service && projectTask.service.procedures.map(function(procedure) {
					return (
						<span key={procedure.id}>
							<h4 className="bottom12">{procedure.name}</h4>

							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell">Lp.</div>
									<div className="boxCell">Nazwa zadania</div>
									<div className="boxCell">Przydzielona osoba</div>
									<div className="boxCell">Prognozowana data wykonania</div>
									<div className="boxCell">Graniczna data wykonania</div>
									<div className="boxCell">Status</div>
								</div>

								{procedure.tasks && procedure.tasks.map(function(task, index) {
									return [
										<div className={"boxRow withBorder" + (projectTask.task && (projectTask.task.id === task.id) ? ' selected' : '')} key={"tag" + task.id}>
											<div className="boxCell">{index + 1}</div>
											<div className="boxCell">{task.name}</div>
											<div className="boxCell">
												{task.user && task.user.hasOwnProperty('name')? task.user.name : ''}
											</div>
											<div className="boxCell">
												{task.dateRealization ? formatLocalizedDate(new Date(task.dateRealization)) : ''}
											</div>
											<div className="boxCell">
												{task.dateRealizationLimit ? formatLocalizedDate(new Date(task.dateRealizationLimit)) : ''}
											</div>
											<div className="boxCell"><PillStatus value={task.status}/></div>
										</div>,
										<div className="boxRow spacer" key={"taskSpacer" + task.id}/>
									];
								})}
							</div>
						</span>
					);
				})}
			</>
		);
	}
}
