import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {useHistory, useParams} from "react-router-dom";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import ProjectForm, {ProjectTaskForm, validateProject} from "./projectForm";
import Spinner from "../component/spinner";
import {connect, useDispatch} from "react-redux";
import {actionFetchPeople} from "../state/actionsPeople";
import {actionFetchProcedures} from "../state/actionsProcedures";
import BackButton from "../component/backButton";
import moment from 'moment';
import {formikErrorHelper} from "../form/formUtils";

const mapStateToProps = function(state) {
	return {
		procedures: state.procedures.list,
		loading: state.procedures.loading,
	};
};

function ProjectEdit(props) {
	let {id} = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [project, setProject] = useState({});
	const [service, setService] = useState({});
	const [tasks, setTasks] = useState({});
	const [tasksToSave, setTasksToSave] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	async function fetchProjectTaskService(serviceId, procedureId, taskId) {
		///project/task/{projectId}/{taskId}/{procedureId}/{serviceId}
		return axios.get(API_URL + '/project/task/' + project.id + '/' + taskId + '/' + procedureId + '/' + serviceId)
			.then(function(response) {
				//console.log(response.data.data.project);

				let foo = objectClone(response.data.data.project);

				if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('id')) {
					foo.user = foo.user.id;
				}

				if (tasks.hasOwnProperty(procedureId) === false) {
					tasks[procedureId] = {};
				}

				tasks[procedureId][taskId] = foo;
				setTasks(tasks);
			}).catch(function(error) {
				//console.log(error);
				if (tasks.hasOwnProperty(procedureId) === false) {
					tasks[procedureId] = {};
				}

				tasks[procedureId][taskId] = {};
				setTasks(tasks);
		});
	}

	async function fetchService(id = null) {
		if (id !== null) {
			setService({});

			const response = await axios(API_URL + '/definition/service/' + id);
			//console.log(response.data.data.service);
			if (response.data.data.service) {
				if (Array.isArray(response.data.data.service.procedures)) {
					let promisesArray = [];

					response.data.data.service.procedures.map(function(procedure) {
						if (Array.isArray(procedure.tasks)) {
							procedure.tasks.map(function(task) {
								promisesArray.push(fetchProjectTaskService(response.data.data.service.id, procedure.id, task.id));
							})
						}
					});

					Promise.all(promisesArray).then(() => {
						setService(response.data.data.service);
					});

				} else {
					setService(response.data.data.service);
				}
			}
		}
	}

	useEffect(() => {
		fetchService(project.services);
	}, [project.services]);

	useEffect(() => {
		async function fetchProject() {
			const response = await axios(API_URL + '/project/' + id);
			//console.log(response.data.data);
			if (response.data.data.project) {
				let foo = objectClone(response.data.data.project);

				if (foo.hasOwnProperty('type') && foo.type.hasOwnProperty('id')) {
					foo.type = foo.type.id;
				}
				if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('id')) {
					foo.client = foo.client.id;
				}
				if (foo.hasOwnProperty('company') && foo.company.hasOwnProperty('id')) {
					foo.company = foo.company.id;
				}
				if (foo.hasOwnProperty('services') && foo.services.hasOwnProperty('id')) {
					foo.services = foo.services.id;
				}
				if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('id')) {
					foo.client = foo.client.id;
				}
				if (Array.isArray(foo.services) && foo.services.length > 0) {
					foo.services = foo.services.pop();
				}
				if (foo.hasOwnProperty('services') && foo.services.hasOwnProperty('id')) {
					foo.services = foo.services.id;
				}
				if (foo.hasOwnProperty('seller') && foo.seller.hasOwnProperty('id')) {
					foo.seller = foo.seller.id;
				}
				if (foo.hasOwnProperty('unitQuantity') === false) {
					foo.unitQuantity = 1;
				}
				if (foo.hasOwnProperty('unitValue') === false) {
					foo.unitValue = 0;
				}
				if (foo.hasOwnProperty('recurrent')) {
					foo.recurrent = parseInt(foo.recurrent);
				}

				setProject(foo);
			}
		}

		fetchProject();

		dispatch(actionFetchProcedures());
		dispatch(actionFetchPeople());

	}, []);

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (project[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		if (toSend.hasOwnProperty('status')) {
			toSend['status'] = parseInt(toSend['status']);
		}
		if (toSend.hasOwnProperty('seller')) {
			toSend['seller'] = parseInt(toSend['seller']);
		}

		if (toSend.services) {
			toSend.services = [toSend.services];
		}

		function finish() {
			axios.patch(API_URL + '/project/' + id, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					history.goBack();
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		}

		let tasksPromises = saveTasks();

		let promises = [];
		promises.concat(addFiles(uploadedFiles, project.id));
		promises.concat(tasksPromises);

		Promise.all(promises).then(() => {
			if (tasksPromises.length > 0) {
				Promise.all(saveTasksChecks()).then(function() {
					finish();
				});
			} else {
				finish();
			}
		});
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/project/file/' + project.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, projectId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/project/file/' + projectId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function saveTasks() {
		let promisesArray = [];

		for (let procedureId in tasksToSave) {
			for (let taskId in tasksToSave[procedureId]) {
				//console.log(taskId, tasksToSave[taskId]);
				let toSave = tasksToSave[procedureId][taskId];
				toSave.project = project.id;
				toSave.service = service.id;
				toSave.procedure = parseInt(procedureId);
				toSave.task = parseInt(taskId);

				//console.log(toSave);

				promisesArray.push(axios.post(API_URL + '/project/task/assign', toSave));
			}
		}

		return promisesArray;
	}

	function saveTasksChecks() {
		let promisesArray = [];

		for (let procedureId in tasksToSave) {
			promisesArray.push(axios.get(API_URL + '/project/task/checkAssigned/' + project.id + '/' + service.id + '/' + procedureId));
		}

		return promisesArray;
	}

	return (isEmptyObject(project) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(project);
		delete initial.id;
		delete initial.created;
		delete initial.modified;
		delete initial.userModified;
		initial.implementationDate = moment(initial.implementationDate);

		return (
			<Formik
				initialValues={initial}
				validate={function(values) {
					let errors = validateProject(values);
					if (!values.name) {
						errors.name = 'Nazwa jest wymagana';
					}
					return errors;
				}}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<>
							<Form>
								<div className="headerWithButtons">
									<h1>Edycja Projektu</h1>
									<div className="right">
										<BackButton>Anuluj</BackButton>
										<button type="submit" disabled={isSubmitting} className="left32 withChevron">
											Zapisz&nbsp;<i className="fas fa-chevron-right"/>
										</button>
									</div>
								</div>

								<ProjectForm
									{...propsFormik}
									onServiceChange={(name, value) => {
										fetchService(value);
										setFieldValue(name, value);
									}}
									handleFileAdd={handleFileAdd}
									handleFileDelete={handleFileDelete}
									uploadedFiles={uploadedFiles}
									service={service}
								/>
							</Form>

							{/*<Dump value={values} />*/}
							{/*<Dump value={project} />*/}
							{/*<Dump value={service} />*/}
							{/*<Dump value={tasks} />*/}
							{/*<Dump value={tasksToSave} />*/}

							<h3 className="bottom24">Przydziel zadania</h3>
							{(isEmptyObject(service) ? <div className="textCenter top96"><Spinner/></div> : renderTasks()) }
						</>
					)
				}}
			</Formik>
		);
	}

	function renderTasks() {
		return (
			<>
				{service && Array.isArray(service.procedures) && service.procedures.map(function(procedure) {
					return (
						<span key={procedure.id}>
							<h4 className="bottom12">{procedure.name}</h4>

							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell">Lp.</div>
									<div className="boxCell">Nazwa zadania</div>
									<div className="boxCell">Przydzielona osoba</div>
									<div className="boxCell">Graniczna data wykonania</div>
									<div className="boxCell">Status</div>
								</div>

								{procedure.tasks.map(function(task, index) {
									return (
										<ProjectTaskForm
											key={'projectTask' + task.id}
											lp={index + 1}
											procedureId={procedure.id}
											task={task}
											getFieldValue={getFieldValue}
											updateTask={updateTask}
										/>
									);
								})}
							</div>
						</span>
					);
				})}
			</>
		);
	}

	function getFieldValue(taskId, procedureId, name) {
		let value = null;

		if (tasks.hasOwnProperty(procedureId)) {
			if (tasks[procedureId].hasOwnProperty(taskId)) {
				if (tasks[procedureId][taskId].hasOwnProperty(name)) {
					value = tasks[procedureId][taskId][name];
				}
			}
		}

		if (tasksToSave.hasOwnProperty(procedureId)) {
			if (tasksToSave[procedureId].hasOwnProperty(taskId)) {
				if (tasksToSave[procedureId][taskId].hasOwnProperty(name)) {
					value = tasksToSave[procedureId][taskId][name];
				}
			}
		}

		return value;
	}

	function updateTask(taskId, procedureId, prop, value) {
		let foo = objectClone(tasksToSave);

		if (foo.hasOwnProperty(procedureId) === false) {
			foo[procedureId] = {};
		}

		if (foo[procedureId].hasOwnProperty(taskId) === false) {
			foo[procedureId][taskId] = {};
		}

		foo[procedureId][taskId][prop] = value;

		setTasksToSave(foo);
	}
}

export default connect(mapStateToProps)(ProjectEdit);