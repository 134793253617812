import React, {useEffect, useState} from 'react'
import AdminServiceRow from "./adminServiceRow";
import Sortable from "../../component/sortable";
import Spinner from "../../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import AdminServiceAdd from "./adminServiceAdd";
import AdminServiceEdit from "./adminServiceEdit";
import AdminServiceView from "./adminServiceView";
import axios from "axios";
import {useDispatch} from "react-redux";
import {actionFetchServices} from "../../state/actionsServices";

export default function AdminServiceList(props) {
	const dispatch = useDispatch();

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [servicesCount, setServicesCount] = useState(1);

	const [search, setSearch] = useState("");
	const [services, setServices] = useState(null);
	const [serviceId, setServiceId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('modifiedDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchServices() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/definition/service/list', {params: toSend});

		if (Array.isArray(response.data.data.list.list)) {
			setServices(response.data.data.list.list);
			setServicesCount(response.data.data.list.count);
		}
	}

	useEffect(() => {
		fetchServices();
	}, [search, currentPage, perPage, orderDirection, orderColumn]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setServiceId(id);
		setShowModalView(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setServiceId(id);
		setShowModalEdit(true);
	}

	function handleRefresh() {
		dispatch(actionFetchServices());
		fetchServices();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Definicje - Usługi</h1>

					<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</button>
				</div>
				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa zadania</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="department" value={(orderColumn === 'department') ? orderDirection : null}>Dział</Sortable></div>
					{/*<div className="boxCell"><Sortable onChange={handleSortChange} index="type" value={(orderColumn === 'type') ? orderDirection : null}>Typ</Sortable></div>*/}
					<div className="boxCell"><Sortable onChange={handleSortChange} index="procedure" value={(orderColumn === 'procedure') ? orderDirection : null}>Procedura</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modified" value={(orderColumn === 'modified') ? orderDirection : null}>Data aktualizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedBy" value={(orderColumn === 'modifiedBy') ? orderDirection : null}>Przez kogo</Sortable></div>
					<div className="boxCell"/>
				</div>

				{services && services.map(function(item, index) {
					return [
						<AdminServiceRow key={'task' + index} value={item} handleOpenModalEdit={handleOpenModalEdit} handleOpenModalView={handleOpenModalView} refresh={handleRefresh}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(services == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={servicesCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={documents}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filters}/>*/}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminServiceAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminServiceEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} serviceId={serviceId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminServiceView handleClose={handleCloseModalView} serviceId={serviceId} />
			</ReactModal>
		</div>
	);
}
