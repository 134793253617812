import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom"
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import PackageRow from "./packageRow";
import FilterDirection from "./filterDirection";
import ReactModal from "react-modal";
import FilterPackage from "./filterPackage";
import {formatLocalizedDate, getNameFromId, objectClone} from "../utils";
import {connect, useDispatch} from "react-redux";
import {actionFetchClients} from "../state/actionsClients";
import {actionFetchCompanies} from "../state/actionsCompanies";
import PackageView from "./packageView";

const mapStateToProps = function(state) {
	return {
		clients: state.clients.list,
		clientsLoading: state.clients.loading,
		companies: state.companies.list,
		companiesLoading: state.companies.loading,
	};
};

function PackageList(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.clientsLoading === false && (props.clients == null || props.clients.length === 0)) {
			dispatch(actionFetchClients());
		}
	}, []);

	useEffect(() => {
		if (props.companiesLoading === false && (props.companies == null || props.companies.length === 0)) {
			dispatch(actionFetchCompanies());
		}
	}, []);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [packageCount, setPackageCount] = useState(1);

	const [search, setSearch] = useState("");
	const [packages, setPackages] = useState(null);

	const [orderColumn, setOrderColumn] = useState('operationDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [filterSelectedDirection, setFilterSelectedDirection] = useState(null);
	const [filters, setFilters] = useState({});
	const [showModalFilters, setShowModalFilters] = useState(false);

	const [packageId, setPackageId] = useState(null);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchPackages() {
		let toSend = {
			'filter[category]': "parcel",
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (filterSelectedDirection) {
			toSend["filter[direction]"] = filterSelectedDirection;
		}

		if (filters.hasOwnProperty('type') && filters.type.length > 0) {
			toSend['filter[type]'] = filters.type.map((item) => item.id);
		}
		if (filters.hasOwnProperty('client')) {
			toSend['filter[client]'] = filters.client;
		}
		if (filters.hasOwnProperty('company')) {
			toSend['filter[company]'] = filters.company;
		}
		if (filters.hasOwnProperty('dateFrom')) {
			toSend['filter[dateFrom]'] = filters.dateFrom.format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('dateTo')) {
			toSend['filter[dateTo]'] = filters.dateTo.format('YYYY-MM-DD');
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/reception/list', {params: toSend});

		let data = response.data.data;
		//console.log(data);
		if (Array.isArray(data.list)) {
			setPackages(data.list);
			setPackageCount(data.count);
		}
	}

	useEffect(() => {
		fetchPackages();
	}, [search, currentPage, perPage, orderDirection, orderColumn, filterSelectedDirection, filters]);

	function handleSortChange(index, direction) {
		setOrderDirection(direction);
		setOrderColumn(index);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setPackageId(id);
		setShowModalView(true);
	}

	function handleRefresh() {
		fetchPackages();
	}

	function getReportUrl() {
		let url = API_URL + '/reception/download/report?access-token=' + localStorage.getItem('accessToken');

		if (filterSelectedDirection) {
			url = url + '&filter[direction]=' + filterSelectedDirection;
		}

		if (filters.hasOwnProperty('type') && filters.type.length > 0) {
			url = url + '&filter[type]=' + filters.type.map((item) => item.id);
		}
		if (filters.hasOwnProperty('client')) {
			url = url + '&filter[client]=' + filters.client;
		}
		if (filters.hasOwnProperty('company')) {
			url = url + '&filter[company]=' + filters.company;
		}
		if (filters.hasOwnProperty('dateFrom')) {
			url = url + '&filter[dateFrom]=' + filters.dateFrom.format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('dateTo')) {
			url = url + '&filter[dateTo]=' + filters.dateTo.format('YYYY-MM-DD');
		}

		return url;
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Recepcja - Przesyłki</h1>
					<Link to="/package/add" className="button left32 withChevron">
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</Link>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>

				<div className="right">
					<Link to="/guest" className="button right32 darkBlue">Goście</Link>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={function(event) { setSearch(event.target.value); }}/>
				</div>
			</div>

			<FilterDirection onChange={value => setFilterSelectedDirection(value)} />

			{(packages && packageCount > 0) ? <a className="button green floatRight left24" href={getReportUrl()}>Generuj raport</a> : ''}

			<div className="filters">
				{filters.type && filters.type.map((item) => {
					return (
						<span key={"filterType" + item.id} className="filter">
							<i className="fas fa-times" onClick={() => {
								let newFilters = objectClone(filters);
								if (newFilters.type.indexOf(item) > -1) {
									newFilters.type.splice(newFilters.type.indexOf(item), 1);
									setFilters(newFilters);
								}
							}}/>
							{item.name}
						</span>
					);
				})}

				{filters.client ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.client;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.clients, filters.client)}
					</span>
				) : ''}

				{filters.company ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.company;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.companies, filters.company)}
					</span>
				) : ''}

				{filters.dateFrom ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.dateFrom;
							setFilters(newFilters);
						}}/>
						Od {formatLocalizedDate(filters.dateFrom.toDate())}
					</span>
				) : ''}

				{filters.dateTo ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.dateTo;
							setFilters(newFilters);
						}}/>
						Do {formatLocalizedDate(filters.dateTo.toDate())}
					</span>
				) : ''}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="description" value={(orderColumn === 'description') ? orderDirection : null}>Opis</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="type" value={(orderColumn === 'type') ? orderDirection : null}>Rodzaj</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Nadawca/Odbiorca</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="company" value={(orderColumn === 'company') ? orderDirection : null}>Spółka</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="operationDate" value={(orderColumn === 'operationDate') ? orderDirection : null}>Data wysyłki / odbioru</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="taskNumber" value={(orderColumn === 'taskNumber') ? orderDirection : null}>nr nadania</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="sysNumber" value={(orderColumn === 'sysNumber') ? orderDirection : null}>nr systemowy</Sortable></div>
					<div className="boxCell"/>
				</div>

				{packages && packages.map(function(item, index) {
					return [
						<PackageRow key={'package' + index} value={item} refresh={handleRefresh} handleOpenModalView={handleOpenModalView}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(packages == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={packageCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<FilterPackage filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<PackageView handleClose={handleCloseModalView} packageId={packageId} />
			</ReactModal>

			{/*<Dump value={filters}/>*/}
			{/*{<Dump label="column" value={orderColumn}/>}*/}
			{/*{<Dump label="direction" value={orderDirection}/>}*/}
		</div>
	);
}

export default connect(mapStateToProps)(PackageList);