import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {connect, useDispatch} from "react-redux";
import {parseISO} from "date-fns";
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../../form/field";
import {findObjectWithPropertyValueInArray, findIndexOfObjectWithPropertyValueInArray, formatLocalizedDate, isEmptyObject, objectClone} from "../../utils";
import {validateService} from "./adminServiceForm";
import Spinner from "../../component/spinner";
import SelectDepartment from "../../form/selectDepartment";
import {formikErrorHelper} from "../../form/formUtils";
import PopupMenu from "../../component/popupMenu";
import ReactModal from "react-modal";
import SelectPopupProcedure from "../../popupSelect/selectPopupProcedure";
import {actionFetchProcedures} from "../../state/actionsProcedures";
import FileView from "../../component/fileView";
import FileUpload from "../../component/fileUpload";
import SelectProjectType from "../../form/selectProjectType";

const mapStateToProps = function(state) {
	return {
		procedures: state.procedures.list,
		loading: state.procedures.loading,
	};
};

function AdminServiceEdit(props) {
	const dispatch = useDispatch();

	const [service, setService] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [proceduresToAdd, setProceduresToAdd] = useState([]);
	const [proceduresToDelete, setProceduresToDelete] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		async function fetchService() {
			const response = await axios(API_URL + '/definition/service/' + props.serviceId);
			//console.log(response.data.data);
			if (response.data.data.service) {
				setService(response.data.data.service);
			}
		}

		fetchService();
		dispatch(actionFetchProcedures());
	}, [props.serviceId]);

	function handleOpenModal() {
		setShowModal(true);
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/definition/service/file/' + service.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, serviceId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/service/file/' + serviceId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}


	function handleCloseModal(newId) {
		if (Number.isInteger(newId) && proceduresToAdd.indexOf(newId) === -1) {
			setProceduresToAdd([newId, ...proceduresToAdd]);
		}

		setShowModal(false);
	}

	function handleTaskDelete(id) {
		if (proceduresToAdd.indexOf(id) !== -1) {
			setProceduresToAdd(proceduresToAdd.filter(function(value) { return value !== id; }));
		} else if (proceduresToDelete.indexOf(id) === -1) {
			setProceduresToDelete([id, ...proceduresToDelete]);
		}
	}

	function addProcedures() {
		let promisesArray = [];

		if (proceduresToAdd && Array.isArray(proceduresToAdd) && proceduresToAdd.length > 0) {
			proceduresToAdd.map((procedureId) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/service/procedure/' + service.id + '/' + procedureId)
				);
			});
		}

		return promisesArray;
	}

	function deleteProcedures() {
		let promisesArray = [];

		if (proceduresToDelete && Array.isArray(proceduresToDelete) && proceduresToDelete.length > 0) {
			proceduresToDelete.map((procedureId) => {
				promisesArray.push(
					axios.delete(API_URL + '/definition/service/procedure/' + service.id + '/' + procedureId)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (service[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		delete toSend.userModified;
		delete toSend.files;

		if (toSend.hasOwnProperty('department')) {
			toSend['department'] = parseInt(toSend['department']);
		}

		let promises = [];
		promises.concat(addProcedures());
		promises.concat(deleteProcedures());
		promises.concat(addFiles(uploadedFiles, props.serviceId));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/definition/service/' + props.serviceId, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
		});
	}

	return (isEmptyObject(service) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(service);
		delete initial.id;
		delete initial.created;
		delete initial.modified;
		delete initial.userModified;

		if (initial.hasOwnProperty('department') && initial.department.hasOwnProperty('id')) {
			initial.department = initial.department.id;
		} else {
			initial.department = null;
		}

		if (initial.hasOwnProperty('type') && initial.type.hasOwnProperty('id')) {
			initial.type = initial.type.id;
		} else {
			initial.type = null;
		}

		if (initial.hasOwnProperty('userModified') && initial.userModified.hasOwnProperty('name')) {
			initial.userModified = initial.userModified.name;
		} else {
			initial.userModified = '';
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateService}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<>
							<Form>
								<div className="rcModelContents">
									<h3 className="top0 bottom24">Edycja Usługi</h3>
									<div className="formItem">
										<Field className="fullWidth" type="text" name="name" style={{ fontSize: '18px'}}/>
										<ErrorMessage component="div" className="errorMessage" name="name"/>
									</div>

									<div className="row with four columns">
										<div className="formItem column">
											<label htmlFor="name">Przypisz do działu</label>
											<SelectDepartment
												name="department"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												value={values.department}
												className="fullWidth"
											/>
											<ErrorMessage component="div" className="errorMessage" name="department"/>
										</div>

										<div className="formItem column">
											<label htmlFor="type">Typ</label>
											<SelectProjectType
												name="type"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												value={values.type}
												className="fullWidth"
											/>
											<ErrorMessage component="div" className="errorMessage" name="type"/>
										</div>

										<div className="column">
											<div className="formItem">
												<label>Data ostatniej zmiany</label>
												<input type="text" readOnly value={formatLocalizedDate(parseISO(service.modified))} className="fullWidth"/>
											</div>
										</div>
										<div className="column">
											<div className="formItem">
												<label>Przez kogo</label>
												<input type="text" readOnly value={service.userModified ? service.userModified.name : ''} className="fullWidth"/>
											</div>
										</div>
									</div>

									<div className="formItem">
										<label htmlFor="description">Opis zadania</label>
										<Field className="fullWidth" component="textarea" name="description" rows="4"/>
										<ErrorMessage component="div" className="errorMessage" name="description"/>
									</div>

									<div className="row with four columns">
										{values.files && values.files.map(function(file, index) {
											return (
												<div className="formItem column" key={file.id}>
													{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
													<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, values.files, setFieldValue)}/>
												</div>
											);
										})}
										{uploadedFiles.map(function(file) {
											return (
												<div className="formItem column" key={file.id}>
													<label>&nbsp;</label>
													<FileView fileId={file.id} path={file.path} handleDelete={() => handleFileDelete(file.id, values.files, setFieldValue)}/>
												</div>
											)
										})}
									</div>

									<FileUpload handleFile={handleFileAdd}/>

									<div className="formItem bottom0">
										<label>Przypisz procedury</label>
									</div>

									<div className="row with four columns bottom12">
										{values.procedures.map((item) => {
											if (proceduresToDelete.indexOf(item.id) === -1) {
												return (
													<div className="column boxItem bottom12" key={"procedure" + item.id}>
														{item.name}

														<PopupMenu>
															<li onClick={() => {handleTaskDelete(item.id)}} className="red">Usuń</li>
														</PopupMenu>
													</div>
												)
											}
											return null;
										})}

										{proceduresToAdd.map((id) => {
											let item = findObjectWithPropertyValueInArray(props.procedures, id);

											if (typeof item === "object" && item !== null && findObjectWithPropertyValueInArray(values.procedures, item.id) === null) { // if not added currently
												return (
													<div className="column boxItem bottom12" key={"task" + item.id}>
														{item.name}

														<PopupMenu>
															<li onClick={() => {handleTaskDelete(item.id)}} className="red">Usuń</li>
														</PopupMenu>
													</div>
												)
											}
											return null;
										})}

										<div className="column boxItem textCenter bottom12">
											<button className="top20 plus" onClick={(event) => {event.preventDefault(); handleOpenModal(); }}>
												<i className="fas fa-plus"/>
											</button>
										</div>
									</div>

									{status && <div className="errorMessage">{status}</div>}

									{/*<Dump value={initial} />*/}
									{/*<Dump value={proceduresToAdd} />*/}

									<div className="rcModalActions">
										<button type="submit" disabled={isSubmitting}>Zapisz</button>
									</div>
								</div>
							</Form>
							<ReactModal
								isOpen={showModal}
								contentLabel=""
								onRequestClose={handleCloseModal}
								shouldCloseOnOverlayClick={true}
								className="rcModal withActions"
								overlayClassName="rcOverlay"
							>
								<span onClick={handleCloseModal} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

								<SelectPopupProcedure handleClose={handleCloseModal}/>
							</ReactModal>
						</>
					)
				}}
			</Formik>
		);
	}
}

export default connect(mapStateToProps)(AdminServiceEdit );