import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import SelectClient from "../form/selectClient";
import SelectService from "../form/selectService";
import SelectPerson from "../form/selectPerson";
import DatePicker from "../form/datePicker";
import moment from "moment";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";
import {getCompanyFromService, getDepartmentFromService, getProjectTypeFromService} from "./projectUtils";
import PillStatus from "../component/pillStatus";
import SelectInterval from "../form/selectInterval";
import CheckBox from "../form/checkBox";

export default function ProjectForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	function renderType(type) {
		if (type && type.hasOwnProperty('name')) {
			return type.name;
		}

		return '';
	}

	function renderCompany(company) {
		if (company && company.hasOwnProperty('name')) {
			return company.name;
		}

		return '';
	}

	return (
		<div className="box">
			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="name">Nazwa projektu</label>
					<Field className="fullWidth" type="text" name="name"/>
					<ErrorMessage component="div" className="errorMessage" name="name"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="client">Klient</label>
					<SelectClient
						name="client"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.client}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="client"/>
				</div>

				<div className="formItem column">
					<label>Spółka</label>
					<input readOnly type="text" name="company" value={renderCompany(getCompanyFromService(props.service))} className="fullWidth"/>
					<ErrorMessage component="div" className="errorMessage" name="company"/>
				</div>

				<div className="formItem column">
					<label htmlFor="salesPerson">Sprzedawca</label>
					<SelectPerson
						name="seller"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.seller}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="seller"/>
				</div>

				<div className="formItem column">
					<label>Typ usługi</label>
					<input readOnly type="text" name="type" value={renderType(getProjectTypeFromService(props.service))} className="fullWidth"/>
					<ErrorMessage component="div" className="errorMessage" name="type"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label>Dział</label>
					<input readOnly type="text" name="department" value={renderType(getDepartmentFromService(props.service))} className="fullWidth"/>
					<ErrorMessage component="div" className="errorMessage" name="department"/>
				</div>


				<div className="formItem column">
					<label htmlFor="unitQuantity">Ilość jednostkowa</label>
					<Field type="number" name="unitQuantity" className="fullWidth"/>
					<ErrorMessage component="div" className="errorMessage" name="unitQuantity"/>
				</div>

				<div className="formItem column">
					<label htmlFor="unitValue">Wartość jednostkowa</label>
					<Field type="number" name="unitValue" className="fullWidth"/>
					<ErrorMessage component="div" className="errorMessage" name="unitValue"/>
				</div>

				<div className="formItem column">
					<label htmlFor="revenue">Planowana wartość (PLN)</label>
					<input readOnly type="text" name="revenue" value={values.unitQuantity * values.unitValue} className="fullWidth"/>
				</div>
			</div>

			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="recurrent">Powtarza się</label>
					<SelectInterval
						name="recurrent"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.recurrent}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="recurrent"/>
				</div>

				{values.recurrent != 0 ? '' :
					<div className="formItem column">
						<label htmlFor="implementationDate">Planowana data wykonania</label>
						<DatePicker
							name="implementationDate"
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							value={values.implementationDate}
							className="fullWidth"
						/>
						<ErrorMessage component="div" className="errorMessage" name="implementationDate"/>
					</div>
				}
			</div>

			<div className="formItem">
				<label htmlFor="description">Opis projektu</label>
				<Field className="fullWidth" component="textarea" name="description" rows="4"/>
				<ErrorMessage component="div" className="errorMessage" name="description"/>
			</div>

			<div className="row with four columns">
				{values.files && values.files.map(function(file, index) {
					return (
						<div className="formItem column" key={file.id}>
							{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					);
				})}
				{props.uploadedFiles.map(function(file) {
					return (
						<div className="formItem column" key={file.id}>
							<label>&nbsp;</label>
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					)
				})}
			</div>

			<FileUpload handleFile={props.handleFileAdd}/>

			<div className="row with two columns">
				<div className="formItem column">
					<label htmlFor="services">Usługa realizowana w Projekcie</label>
					<SelectService
						name="services"
						onChange={props.onServiceChange}
						onBlur={setFieldTouched}
						value={values.services}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="services"/>
				</div>
			</div>

			{status && <div className="errorMessage">{status}</div>}

			{/*<Dump label="errors" value={errors}/>*/}
			{/*<Dump label="formik" value={props}/>*/}
			{/*<Dump value={tasksToSave}/>*/}
			{/*<Dump value={values}/>*/}
		</div>
	)
}

export function ProjectTaskForm(props) {

	let dateValue = props.getFieldValue(props.task.id, props.procedureId, 'dateRealizationLimit');
	if (dateValue !== null) {
		dateValue = moment(dateValue);
	}

	function renderStatus(status) {
		if (status && status.hasOwnProperty('id') && status.hasOwnProperty('name')) {
			return (<PillStatus value={status}/>);
		}

		return '';
	}

	return (
		<>
			<div className="boxRow withBorder" key={props.task.id}>
				<div className="boxCell">{props.lp}</div>
				<div className="boxCell">{props.task.name}</div>
				<div className="boxCell">
					<SelectPerson
						name="user"
						value={props.getFieldValue(props.task.id, props.procedureId, 'user')}
						onChange={(name, value) => {props.updateTask(props.task.id, props.procedureId, name, value);}}
						className="fullWidth"
					/>
				</div>
				<div className="boxCell">
					<CheckBox
						name="finalDate"
						checked={props.getFieldValue(props.task.id, props.procedureId, 'finalDate')}
						onChange={(name, value) => {props.updateTask(props.task.id, props.procedureId, name, value);}}
						className="top10"
					/>
					<DatePicker
						name="dateRealizationLimit"
						value={dateValue}
						className="noMinimumWidth"
						onChange={(name, value) => {props.updateTask(props.task.id, props.procedureId, name, value);}}
					/>
				</div>
				<div className="boxCell">{renderStatus(props.getFieldValue(props.task.id, props.procedureId, 'status'))}</div>
			</div>
			<div className="boxRow spacer"/>
		</>
	);
}

export function validateProject(values) {
	let errors = {};
	if (!values.services) {
		errors.services = 'Usługa jest wymagana';
	}
	if (!values.client) {
		errors.client = 'Klient jest wymagany';
	}
	return errors;
}
